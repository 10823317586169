import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getPaymentGateways } from '../../../setup/redux/effects/UtilsEffects';
import { RootState } from '../../../setup/Store';
import { PaymentGateway } from '../../models/PaymentGateway';

export const paymentGatewaysQueryKey = ['paymentGateways'];

const usePaymentGatewaysQuery = ({ shouldFetch = false }) => {
  const country = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting?.country
  );
  const currency = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting?.default_currency
  );
  return useQuery({
    queryKey: [...paymentGatewaysQueryKey, shouldFetch, country, currency],
    queryFn: async () => {
      if (!shouldFetch) return [] as PaymentGateway[];
      const { data } = await getPaymentGateways({
        country,
        currency,
      });
      return data;
    },
  });
};

export default usePaymentGatewaysQuery;
