import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import React, { ReactNode, useState } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { addTicket } from '../../../../setup/redux/effects/TicketsEffects';
import { RootState } from '../../../../setup/Store';

function NewTicket({
  afterSubmit,
  children,
}: {
  afterSubmit: () => void;
  children?: ReactNode | ((handleShowModal: () => void) => ReactNode);
}) {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
  });
  const [attachments, setAttachments] = useState<File[]>([]);
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const handleChange = (e: any) => {
  //   const { name, value, files } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: files ? files[0] : value,
  //   });
  // };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      // Handle form submission logic here
      await addTicket(formData, attachments);
      setFormData({
        subject: '',
        message: '',
      });
      setAttachments([]);
      afterSubmit();
      handleHidemodal();
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };
  const locale = useSelector((state: RootState) => state.utils.locale);
  const handleShowModal = () => {
    setFormData({
      subject: '',
      message: '',
    });
    setAttachments([]);
    setShowModal(true);
  };
  const handleHidemodal = () => setShowModal(false);
  const handleAddAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setAttachments([...attachments, ...Array.from(event.target.files)]);
    }
  };
  return (
    <>
      {typeof children === 'function' ? children(handleShowModal) : children}
      <Modal show={showModal} centered onHide={handleHidemodal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='TICKETS.CREATE' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row g-4 w-100'>
            <div className='border-bottom border-top py-1'>
              <input
                className='form-control form-control-transparent border-0'
                name='compose_subject'
                placeholder={formatMessage({ id: 'TICKETS.SUBJECT' })}
                value={formData.subject}
                onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              />
            </div>
            <div>
              <CKEditor
                editor={Editor}
                data={formData.message || ''}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setFormData({
                    ...formData,
                    message: data,
                  });
                }}
                config={{
                  language: locale,
                  toolbar: ['undo', 'redo', 'bold', 'italic', 'numberedList', 'bulletedList'],
                  placeholder: formatMessage({ id: 'TICKETS.MESSAGE.PLACEHOLDER' }), // Add the placeholder text here
                }}
              />
            </div>
            {!!attachments?.length && (
              <div className='d-flex flex-column gap-2'>
                <span className='form-label'>
                  <FormattedMessage id='TICKETS.ATTACHEMENTS.LIST' />
                </span>
                <ul className='list-group'>
                  {attachments.map((file, index) => (
                    <li
                      key={index}
                      className='list-group-item d-flex justify-content-between align-items-center'>
                      {file.name}
                      <button
                        type='button'
                        className='btn btn-danger btn-sm'
                        onClick={() => {
                          setAttachments(attachments.filter((_, i) => i !== index));
                        }}>
                        <FormattedMessage id='FORM.DELETE' />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
        <ModalFooter className='d-flex justify-content-between gap-4'>
          <input
            type='file'
            multiple
            onChange={handleAddAttachment}
            style={{ display: 'none' }}
            id='attachment-input'
          />
          <label
            htmlFor='attachment-input'
            className='btn btn-light btn-active-light-primary btn-flex d-none d-sm-flex'>
            <i className='fa-light fa-paperclip'></i>
            <FormattedMessage id='TICKETS.ATTACHEMENTS.ADD' />
          </label>
          <label
            htmlFor='attachment-input'
            className='btn btn-light btn-active-light-primary btn-flex btn-icon d-sm-none btn-sm'>
            <i className='fa-light fa-paperclip'></i>
          </label>
          <div className='d-flex align-items-center gap-4'>
            <button
              type='button'
              className='btn btn-light btn-active-light-primary'
              onClick={handleHidemodal}>
              <FormattedMessage id='COMMON.CANCEL' />
            </button>
            <button
              type='button'
              className='btn btn-primary'
              disabled={isSubmitting}
              onClick={handleSubmit}>
              <FormattedMessage id='FORM.SUBMIT' />
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default NewTicket;
