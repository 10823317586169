import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadToken } from '../setup/redux/reducers/AuthReducer';
import './assets/react-datepicker.css';
import SWToastContainer from './components/SWToastContainer/SWToastContainer';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';
import InternalisationProvider from './i18n/InternalisationProvider';
import { LayoutProvider } from './layout/core';
import { Routes } from './routing/AppRoutes';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnReconnect: true, staleTime: 60000 } },
});
const TNApp = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadToken());
  }, []);
  return (
    <InternalisationProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <SWToastContainer />
        <ThemeProvider>
          <BrowserRouter>
            <LayoutProvider>
              <Routes />
            </LayoutProvider>
          </BrowserRouter>
        </ThemeProvider>
        {/* // )} */}
      </QueryClientProvider>
    </InternalisationProvider>
  );
};

export default TNApp;
