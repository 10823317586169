import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ProfileExtraModule } from '../../models/ProfileExtras';

export const profileExtrasQueryKey = ['profileExtras'];

const useProfileExtrasQuery = (shouldFetch: boolean) =>
  useQuery({
    queryKey: [...profileExtrasQueryKey, shouldFetch],
    queryFn: async () => {
      if (!shouldFetch) return [] as ProfileExtraModule[];
      const { data } = await axios.get<ProfileExtraModule[]>('/secure/user/extras');
      return data;
    },
  });

export default useProfileExtrasQuery;
