/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../setup/Store';
import { signOut } from '../../../../setup/redux/reducers/AuthReducer';
import Guard, {
  authorizeOnRole,
  authorizeOnRoleOrPermissionAndPermissionPlan,
} from '../../../components/Security/Guard';
import { toAbsoluteUrl } from '../../../helpers';
import { Languages } from './Languages';
import { LayoutTypeKey } from '../../../layout/core';
import { useLocalStorage } from 'usehooks-ts';
import Image from '../../../components/Image/Image';
import { postDraft } from '../../../../setup/redux/effects/DocumentEffects';

const HeaderUserMenu: FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addNewPOS = async () => {
    const { data } = await postDraft({ type: 3, pos: true });
    navigate(`/pos/${data.id}`);
  };
  const handleSignOut = () => {
    dispatch(signOut());
  };
  const [sidebarTheme, setSidebarTheme] = useState(localStorage.getItem(LayoutTypeKey));
  const [hasCheckedTickets] = useLocalStorage('has_checked_Tickets', false);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <Image
              src={user?.avatar?.web_path || ''}
              alt='User logo'
              fallback={<img src='/media/logos/logo.svg' alt='Logo' />}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {`${user?.first_name} ${user?.last_name}`}
              <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>
                <FormattedMessage id='USER.MENU.PRO' />
              </span>
            </div>
            <div className='fw-semibold text-muted text-hover-primary fs-7'>{user?.email}</div>
            <div className='fw-semibold text-primary fs-7'>{user?.reference}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          <FormattedMessage id='USER.MENU.PROFILE' />
        </Link>
      </div>

      <Link to={'/tickets'} className='menu-item px-5'>
        <span className='menu-link px-5 d-flex align-items-center'>
          <span className='flex-grow-1'>
            <FormattedMessage id='USER.MENU.TICKETS' />
          </span>
          {!hasCheckedTickets && (
            <div className='badge badge-warning'>
              <FormattedMessage id='COMMON.NEW' />
            </div>
          )}
        </span>
      </Link>
      <Guard
        planPermissions={['allowPurchase']}
        permissions={['Customer.read.write']}
        roles={['ROLE_ADMIN']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='menu-item px-5'>
          <Link to={'/profile/subscriptions'} className='menu-link px-5'>
            <FormattedMessage id='USER.MENU.SUBSCRIPTION' />
          </Link>
        </div>
      </Guard>
      <div className='separator my-2'></div>
      <Guard
        planPermissions={['invoiceManagement']}
        permissions={['Invoice.read', 'Invoice.read.write']}
        roles={['ROLE_ADMIN', 'ROLE_USER']}
        authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
        <div className='menu-item px-5 my-1 d-flex d-md-none'>
          <span onClick={addNewPOS} className='menu-link px-5'>
            <FormattedMessage id='TOPBAR.POS' />
          </span>
        </div>
        <div className='separator my-2'></div>
      </Guard>
      <Languages type='labeled' />

      <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
        <div className='menu-item px-5 my-1'>
          <Link to='/settings' className='menu-link px-5'>
            <FormattedMessage id='USER.MENU.ACCOUNT.SETTINGS' />
          </Link>
        </div>
      </Guard>

      <div className='menu-item px-3'>
        <div className='menu-content px-3'>
          <label className='form-check form-switch form-check-custom form-check-solid menu-link '>
            <span className=''>
              <FormattedMessage id='HEADER.DARK_SIDEBAR' />
            </span>
            <input
              className='form-check-input w-30px h-20px ms-auto'
              type='checkbox'
              checked={sidebarTheme === 'dark-sidebar'}
              onChange={(e) => {
                setSidebarTheme(e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar');
                localStorage.setItem(
                  LayoutTypeKey,
                  e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar'
                );
                document.body.setAttribute(
                  'data-kt-app-layout',
                  e.currentTarget.checked ? 'dark-sidebar' : 'light-sidebar'
                );
              }}
            />
          </label>
        </div>
      </div>
      <div className='menu-item px-5'>
        <a onClick={handleSignOut} className='menu-link px-5'>
          <FormattedMessage id='USER.MENU.SIGNOUT' />
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
