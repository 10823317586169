import { FormattedMessage } from 'react-intl';
import UpgradeModalDetailsTotal from './upgradeModalDetailsTotal';
import UpgradeModalEntreprises from './upgradeModalEntreprises';
import UpgradeModalExtras from './upgradeModalExtras';
import UpgradeModalMyExtras from './upgradeModalMyExtras';
import UpgradeModalOptions from './upgradeModalOptions';
import UpgradeModalPlansSelection from './upgradeModalPlansSelection';

function UpgradeModalStep1({ isModalShown = false }) {
  return (
    <div className='d-flex gap-6 flex-column'>
      <UpgradeModalPlansSelection isModalShown={isModalShown} />
      <div className='upgrade-modal-details'>
        <div className='upgrade-modal-details-title'>
          <FormattedMessage id='UPGRADE_MODAL.PLAN.DETAILS' />
        </div>
        <div>
          <UpgradeModalOptions />
        </div>
        <UpgradeModalExtras />
        <div className='upgrade-modal-details-title'>
          <FormattedMessage id='UPGRADE_MODAL.PERSONALISED_PLAN' />
        </div>
        <UpgradeModalEntreprises />

        <UpgradeModalMyExtras isModalShown={isModalShown} />
        <UpgradeModalDetailsTotal isModalShown={isModalShown} />
      </div>
    </div>
  );
}

export default UpgradeModalStep1;
