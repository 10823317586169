import React, { createContext, FC, useCallback, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Define allowed file types
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

const bankTransferDefaultValues = {
  reference: '',
  file: undefined as File | undefined,
};
// Define the schema for form validation
const bankTransferSchema = Yup.object().shape({
  reference: Yup.string().required('Reference is required'),
  file: Yup.mixed()
    .required('File is required')
    .test('fileType', 'Unsupported file format. Please upload a PDF or image file.', (value) => {
      if (value && value instanceof File) {
        return SUPPORTED_FORMATS.includes(value.type);
      }
      return false;
    })
    .test('fileSize', 'File size is too large', (value) => {
      if (value && value instanceof File) {
        return value.size <= 1024 * 1024; // 5MB limit
      }
      return false;
    }),
});

type BankTransferFormValues = typeof bankTransferDefaultValues;

type BankTransferContextType = {
  methods: UseFormReturn<BankTransferFormValues>;
  resetForm: () => void;
  paymentAmount: { inclTaxes: number; exclTaxes: number; currency: string } | undefined;
  setPaymentAmount: React.Dispatch<
    React.SetStateAction<{ inclTaxes: number; exclTaxes: number; currency: string } | undefined>
  >;
  paymentId?: number;
  setPaymentId: React.Dispatch<React.SetStateAction<number | undefined>>;
  isPaymentSuccessful?: boolean;
  setIsPaymentSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
};

const BankTransferContext = createContext<BankTransferContextType | undefined>(undefined);

export const useBankTransferContext = () => {
  const context = React.useContext(BankTransferContext);
  if (context === undefined) {
    throw new Error('useBankTransferContext must be used within a BankTransferPaymentProvider');
  }
  return context;
};

interface BankTransferPaymentProviderProps {
  children: React.ReactNode;
}

export const BankTransferPaymentProvider: FC<BankTransferPaymentProviderProps> = ({ children }) => {
  const methods = useForm<BankTransferFormValues>({
    defaultValues: bankTransferDefaultValues,
    resolver: yupResolver(bankTransferSchema),
  });
  const [paymentAmount, setPaymentAmount] = useState<{
    inclTaxes: number;
    exclTaxes: number;
    currency: string;
  }>();
  const [paymentId, setPaymentId] = useState<number>();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

  const resetForm = useCallback(() => {
    methods.reset(bankTransferDefaultValues);
  }, [methods]);

  return (
    <BankTransferContext.Provider
      value={{
        methods,
        resetForm,
        paymentAmount,
        setPaymentAmount,
        paymentId,
        setPaymentId,
        isPaymentSuccessful,
        setIsPaymentSuccessful,
      }}>
      {children}
    </BankTransferContext.Provider>
  );
};

export default BankTransferPaymentProvider;
