import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { FC, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { RootState } from '../../setup/Store';
import { postCustomer } from '../../setup/redux/effects/CustomerEffects';
import { postProduct } from '../../setup/redux/effects/ProductEffects';
import { postSupplier } from '../../setup/redux/effects/SupplierEffects';
import { getWarehouses } from '../../setup/redux/effects/UtilsEffects';
import {
  readCountries,
  readPriceList,
  readTaxes,
  readVats,
} from '../../setup/redux/reducers/UtilsReducers';
import QuickAddContactModal from '../components/QuickAddContactModal/QuickAddContactModal';
import QuickAddProductsModal from '../components/QuickAddProductsModal/QuickAddProductsModal';
import { Customer } from '../models/CustomerResponse';
import { LogisticsInfo, Product, SwImage } from '../models/ProductResponse';
import { Supplier } from '../models/SupplierResponse';
import { Warehouse } from '../models/warehousesResponse';
import {
  customerSchemas,
  supplierSchemas,
} from '../pages/CustomerAndSupplier/AddCustomerOrSupplier/validation/ValidationSchemas';
import AddProductModalsProvider from '../pages/Products/AddProduct/AddProductModalsContext';
import { addSchemas } from '../pages/Products/AddProduct/ProductValidationsSchemas';
import { getPartialPrice } from '../utils/calcUtils';
import PriceListModalProvider from './PriceListModalsContext';

const defaultValues = {
  isAddCustomerOrSupplierModalShown: false,
  isAddProductModalShown: false,
  handleShowAddCustomerOrSupplier: (
    name = '',
    type: 'customer' | 'supplier',
    onSubmit: (contact: Customer | Supplier) => void = () => {}
  ) => {},
  handleHideAddCustomerOrSupplier: () => {},
  handleShowProductModal: (
    name = '',
    warehouse = '',
    documentType = '',
    onSubmit: (product: Product) => void = () => {}
  ) => {},
  handleHideProductModal: () => {},
};
const customersSteps = [
  'FORM.STEPPER.CUSTOMER.TYPE',
  'FORM.STEPPER.CUSTOMER.INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.CONTACT-INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.ADDITIONAL-INFORMATIONS',
];
const suppliersSteps = [
  'FORM.STEPPER.SUPLLIER.INFORMATIONS',
  'FORM.STEPPER.CUSTOMER.CONTACT-INFORMATIONS',
];

const productsSteps = [
  'FORM.STEPPER.PRODUCT.TYPE',
  'FORM.STEPPER.PRODUCT.INFORMATIONS',
  'FORM.STEPPER.PRODUCT.ADDITIONAL-INFORMATIONS',
];

const contactDefaultValues: Partial<Customer | Supplier> = {
  type: '0',
  name: '',
  email: '',
  website: '',
  company_name: '',
  registration: '',
  contact_address: [],
  contact_phones: [],
  civility: 'M',
  reference: '',
  turnover: '0',
  price_list: '',
  contactType: '',
  due_date_number: '',
  due_date_type: '',
};

const QuickAddCustomerSupplierAndProductModalsContext = createContext(defaultValues);

export const useQuickAddCustomerSupplierAndProductModalsContext = () =>
  useContext(QuickAddCustomerSupplierAndProductModalsContext);
const QuickAddCustomerSupplierAndProductModalsProvider: FC = ({ children }) => {
  const company = useSelector((state: RootState) => state.auth.user?.current_company.id);
  const { formatMessage } = useIntl();
  const [isSubmittingContact, setIsSubmittingContact] = useState(false);
  const dispatch = useDispatch();
  const [contactType, setContactType] = useState<'customer' | 'supplier'>();
  const [isAddCustomerOrSupplierModalShown, setIsAddCustomerOrSupplierModalShown] = useState(false);
  const [isAddProductModalShown, setIsAddProductModalShown] = useState(false);
  const [currentContactStep, setCurrentContactStep] = useState(0);
  const [canQuickAddContact, setCanQuickAddContact] = useState(false);
  const [canQuickAddProduct, setCanQuickAddProduct] = useState(false);
  const [isContactSubmit, setIsContactSubmitSubmit] = useState(false);
  const [contactName, setContactName] = useState('');
  const onSubmitContactRef = useRef<(contact: Customer | Supplier) => void>(() => {});
  const onSubmitProductRef = useRef<(contact: Product) => void>(() => {});
  const [currentContactSchema, setCurrentContactSchema] = useState<
    Yup.ObjectSchema<any /* Multiple schemas types */>
  >(Yup.object().shape({}));
  const [currentProductSchema, setCurrentProductSchema] = useState<
    Yup.ObjectSchema<any /* Multiple schemas types */>
  >(addSchemas[0]);
  const [currentProductStep, setCurrentProductStep] = useState(0);
  const countries = useSelector((state: RootState) => state.utils.countries);
  const priceList = useSelector((state: RootState) => state.utils.priceList);
  const vats = useSelector((state: RootState) => state.utils.vats);
  const taxes = useSelector((state: RootState) => state.utils.taxes);
  //   Contacts form control
  const contactMethods = useForm({
    defaultValues: contactDefaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(currentContactSchema),
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    setCurrentProductSchema(addSchemas[currentProductStep]);
    setisProductSubmit(currentProductStep >= productsSteps.length - 1);
    setCanQuickAddProduct(currentProductStep === 1);
  }, [currentProductStep]);
  const brands = useSelector((state: RootState) => state.utils.brands);
  const categories = useSelector((state: RootState) => state.utils.categories);
  const units = useSelector((state: RootState) => state.utils.units);
  const [isSubmittingProduct, setIsSubmittingProduct] = useState(false);
  const [isProductSubmit, setisProductSubmit] = useState(false);
  useEffect(() => {
    setCurrentContactSchema(
      contactType === 'customer'
        ? customerSchemas[currentContactStep]
        : supplierSchemas[currentContactStep]
    );
    setCanQuickAddContact(
      contactType === 'customer'
        ? currentContactStep >= 1 && currentContactStep <= 2
        : currentContactStep === 0
    );
    setIsContactSubmitSubmit(
      contactType === 'customer'
        ? currentContactStep === customersSteps.length - 1
        : currentContactStep === suppliersSteps.length - 1
    );
  }, [contactType, currentContactStep]);
  const resetContactForm = () => {
    // resetConta
    contactMethods.reset(contactDefaultValues, {
      keepValues: false,
      keepErrors: false,
      keepTouched: false,
    });
    setCurrentContactStep(0);
    setIsContactSubmitSubmit(false);
    setIsSubmittingContact(false);
  };

  //   Products form control
  const productsMethods = useForm({
    mode: 'all',
    resolver: yupResolver(currentProductSchema),
    defaultValues: {
      model: 'materiel',
      bar_codes: [],
      brand: '',
      internal_ref: '',
      description: '',
      vat: '',
      product_price_list: [],
      price_list: [],
      tPrice: 'true',
      taxes: [],
      category: '',
      logistic_infos: [],
      allow_empty_stock: true,
      has_serial_number: false,
      manufacturer_ref: '',
      unit_price: 0,
    } as Partial<Product>,
  });
  const { fields: logisticInfoList, append: appendLogisticInfos } = useFieldArray({
    name: 'logistics_infos',
    control: productsMethods.control,
  });
  const resetProduct = () => {
    productsMethods.reset(
      {
        product_price_list: [],
        vat: (vats?.[0].id || '') + '',
        taxes: [],
        bar_codes: [],
        brand: (brands?.[0]?.id || '') + '',
        category: (categories?.[0]?.id || '') + '',
        unit: (units?.[0]?.id || '') + '',
        description: '',
        unit_price: 0,
        tPrice: 'true',
        internal_ref: '',
        allow_empty_stock: true,
        has_serial_number: false,
        logistics_infos: [
          {
            warehouse: (warehouses?.rows?.[0]?.id || '') + '',
            min_stock: 0,
            max_stock: 0,
            allow_alert: false,
          },
        ],
        name: '',
        model: 'materiel',
      },
      {
        keepValues: false,
        keepErrors: false,
        keepTouched: false,
      }
    );
    setCurrentProductStep(0);
  };
  const handleShowAddCustomerOrSupplier = (
    name = '',
    type: 'customer' | 'supplier',
    onSubmit: (contact: Customer | Supplier) => void = () => {}
  ) => {
    contactMethods.setValue('type', type === 'customer' ? '0' : '1');
    if (type === 'supplier') {
      contactMethods.reset(
        { ...contactDefaultValues, company_name: name, type: '1' },
        {
          keepValues: false,
          keepErrors: false,
          keepTouched: false,
        }
      );
    }
    setIsAddCustomerOrSupplierModalShown(true);
    setContactName(name);

    onSubmitContactRef.current = onSubmit;

    setContactType(type);
  };

  const submitContactStep = async (values: Partial<Customer | Supplier>, isQuickAdd = false) => {
    if (isQuickAdd || isContactSubmit) {
      setIsSubmittingContact(true);
      const customerOrSupplier = produce(values, (draft) => {
        draft.type = Number(draft.type);
        draft.company = company;
        draft.enabled = true;
        if (
          draft?.contact_phones?.length &&
          !draft.contact_phones?.find((phone) => phone.is_default)
        ) {
          draft.contact_phones[0].is_default = true;
        }
        if (
          draft?.contact_address?.length &&
          !draft.contact_address?.find((address) => address.is_default)
        ) {
          draft.contact_address[0].is_default = true;
        }
      });
      const { data } = await (contactType === 'customer'
        ? postCustomer(customerOrSupplier as Customer)
        : postSupplier(customerOrSupplier as Supplier));
      onSubmitContactRef.current(data);
      setIsSubmittingContact(false);
      handleHideAddCustomerOrSupplier();
    } else {
      if (currentContactStep === 0 && contactType !== 'supplier') {
        if (+values?.type! === 1) contactMethods.setValue('company_name', contactName);
        else contactMethods.setValue('name', contactName);
      }
      setCurrentContactStep(currentContactStep + 1);
    }
  };
  const getVatById = (id: string) => vats?.find((v) => v.id === Number(id))?.vat || 0;

  const user = useSelector((state: RootState) => state.auth.user);
  const submitProductStep = async (product: Partial<Product>, isQuickAdd = false) => {
    if (isQuickAdd || currentProductStep >= productsSteps.length - 1) {
      setIsSubmittingProduct(true);
      const formData = new FormData();
      formData.append('model', product?.model || '');
      formData.append('name', product?.name || '');
      formData.append('vat', (product?.vat as string) || '');
      (product.taxes as string[])?.forEach((tax, index) => {
        formData.append(`taxes[${index}]`, tax);
      });
      formData.append('allow_empty_stock', !!product?.allow_empty_stock + '');
      formData.append('has_serial_number', !!product?.has_serial_number + '');
      formData.append('company', user?.current_company?.id.toString() || '');
      formData.append('internalRef', product?.internal_ref || '');
      formData.append('manufacturerRef', product?.manufacturer_ref || '');
      formData.append(
        'unit_price',
        ((((p: string | boolean) => p === true || p === 'true')(product?.tPrice!)
          ? +product?.unit_price!
          : getPartialPrice(+product?.unit_price!, getVatById(product.vat! as string))) || 0) + ''
      );
      formData.append('tPrice', product.tPrice!);
      product.product_price_list?.forEach((p, index) => {
        const price = produce(p, (draft) => {
          draft.calcul_method = p.calculType === '1' ? 'ART' : draft.calcul_method;
          delete draft.calculType;
        });
        Object.keys(price)?.forEach((key: string) => {
          formData.append(
            `product_price_list[${index}][${key}]`,
            price[key as keyof typeof price] + ''
          );
        });
      });
      (product.logistics_infos as LogisticsInfo[])?.forEach((l, index) => {
        Object.entries(l)?.forEach(([key, value]) => {
          formData.append(`logistics_infos[${index}][${key}]`, value);
        });
      });

      formData.append('brand', product.brand as string);
      formData.append('category', product.category as string);
      formData.append('unit', product.unit as string);
      formData.append('enabled', 'true');
      formData.append('description', product.description! || '');
      (product.bar_codes as string[])?.forEach((b, index) => {
        formData.append(`bar_codes[${index}]`, b);
      });
      if (product.image instanceof File) {
        formData.append('image', product.image || '');
      } else if (!!product.image) {
        Object.keys(product.image).forEach((key) => {
          formData.append(`image[${key}]`, product.image![key as keyof SwImage] + '');
        });
      }
      try {
        const { data: updatedProduct } = await postProduct(formData);
        queryClient.invalidateQueries(['products']);
        onSubmitProductRef.current(updatedProduct);
        handleHideProductModal();
      } catch (error) {
        toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      }
    }

    setCurrentProductStep(Math.min(currentProductStep + 1, productsSteps.length - 1));
  };
  const { data: warehouses } = useQuery(
    [
      'warehouses',
      {
        company: company,
      },
    ],
    () =>
      getWarehouses({
        company: company,
      }).then((res) => res.data),
    {
      staleTime: 60000,
      refetchOnReconnect: true,
    }
  );
  const previousContactStep = () => {
    setCurrentContactStep(Math.max(0, currentContactStep - 1));
  };
  const previousProductStep = () => {
    setCurrentProductStep(Math.max(0, currentProductStep - 1));
  };
  const [selectedVat] = productsMethods.watch(['vat']);
  useEffect(() => {
    if (priceList !== undefined) return;
    dispatch(readPriceList());
  }, [priceList]);
  useEffect(() => {
    if (!countries?.length) dispatch(readCountries());
  }, [countries]);
  useEffect(() => {
    if (vats === undefined) dispatch(readVats());
    else if (!selectedVat) productsMethods.setValue('vat', vats[0]?.id + '');
  }, [vats]);
  useEffect(() => {
    if (taxes === undefined) dispatch(readTaxes());
  }, [taxes]);

  const handleHideAddCustomerOrSupplier = () => {
    setIsAddCustomerOrSupplierModalShown(false);
    resetContactForm();
    setContactName('');
  };
  const handleHideProductModal = () => {
    setCurrentProductStep(0);
    setIsAddProductModalShown(false);
    setIsSubmittingProduct(false);
    setisProductSubmit(false);
    resetProduct();
  };
  const [documentType, setDocumentType] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const handleShowProductModal = (
    name = '',
    warehouse = '',
    documentType = '',
    onSubmit: (product: Product) => void = () => {}
  ) => {
    if (warehouse) productsMethods.setValue('logistics_infos.0.warehouse', warehouse + '');

    setIsAddProductModalShown(true);
    setDocumentType(documentType);
    setSelectedWarehouse(warehouse);
    productsMethods.setValue('name', name);
    setCurrentProductStep(0);
    onSubmitProductRef.current = onSubmit;
  };
  useEffect(() => {
    if (warehouses === undefined) {
      return;
    } else {
      if (logisticInfoList?.length) {
        productsMethods.setValue(
          'logistics_infos.0.warehouse',
          (warehouses?.rows[0] as Warehouse).id + ''
        );
        productsMethods.setValue('logistics_infos.0.min_stock', 0);
        productsMethods.setValue('logistics_infos.0.max_stock', 0);
      }
    }
  }, [warehouses]);
  return (
    <QuickAddCustomerSupplierAndProductModalsContext.Provider
      value={{
        handleHideAddCustomerOrSupplier,
        handleHideProductModal,
        handleShowAddCustomerOrSupplier,
        handleShowProductModal,
        isAddCustomerOrSupplierModalShown,
        isAddProductModalShown,
      }}>
      {children}
      <PriceListModalProvider>
        <QuickAddContactModal
          handleHideAddCustomerOrSupplier={handleHideAddCustomerOrSupplier}
          isAddCustomerOrSupplierModalShown={isAddCustomerOrSupplierModalShown}
          methods={contactMethods}
          contactType={contactType!}
          currentContactStep={currentContactStep}
          previousContactStep={previousContactStep}
          submitContactStep={submitContactStep}
          canQuickAdd={canQuickAddContact}
          isContactSubmit={isContactSubmit}
        />
      </PriceListModalProvider>
      <AddProductModalsProvider>
        <QuickAddProductsModal
          documentType={documentType}
          show={isAddProductModalShown}
          handleHideModal={handleHideProductModal}
          canQuickAdd={canQuickAddProduct}
          currentStep={currentProductStep}
          methods={productsMethods}
          isSubmit={isProductSubmit}
          previousStep={previousProductStep}
          submitStep={submitProductStep}
          selectedWarehouse={selectedWarehouse}
        />
      </AddProductModalsProvider>
    </QuickAddCustomerSupplierAndProductModalsContext.Provider>
  );
};
export default QuickAddCustomerSupplierAndProductModalsProvider;
