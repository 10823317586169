import { yupResolver } from '@hookform/resolvers/yup';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import * as Yup from 'yup';
import { getLinkPayment } from '../../setup/redux/effects/UtilsEffects';
import { RootState } from '../../setup/Store';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../components/drawer/drawer';
import Lottie from '../components/Lottie';
import {
  UpgradeModalStep2,
  UpgradeModalStep3,
  UpgradeModalStep4,
} from '../components/UpgradeModal';
import { useBankTransferContext } from '../components/UpgradeModal/providers/bankTransferPaymentProvider';
import { ProfileExtraModule } from '../models/ProfileExtras';
import { delayPromise, roundedDifferenceInMonths } from '../utils/utils';

import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import paymentSuccessAnimation from '../assets/paymentSucess.json';
import paymentLoadingAnimation from '../assets/payment_loading.json';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const defaultExtrasValues = {
  paymentType: 2,
  paymentGateway: '',
  companies: 0,
  taxNumber: '',
  users: 0,
  extra_modules: [] as ProfileExtraModule[],
  period: 0,
  periodType: 'MONTHLY',
  extra_company: 0,
  extra_user: 0,
  address: '',
  coupon: '',
};

const validationSchema = Yup.object().shape({
  paymentType: Yup.mixed().required('FORM.ERRORS.REQUIRED'),
  paymentGateway: Yup.mixed().when('paymentType', {
    is: (value: string | number) => +value === 1,
    then: Yup.mixed().required('FORM.ERRORS.REQUIRED'),
  }),
});

const ExtraModalContent = ({
  isModalShown = false,
  currentStep = 0,
}: {
  isModalShown?: boolean;
  currentStep?: number;
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const {
    methods: {
      formState: { isSubmitting: isBankTransferSubmitting },
    },
    isPaymentSuccessful,
  } = useBankTransferContext();

  if (isSubmitting || isBankTransferSubmitting)
    return (
      <div className='d-flex justify-content-center'>
        <Lottie animation={paymentLoadingAnimation} height={300} width={300}></Lottie>
      </div>
    );

  if (currentStep === 3 && isPaymentSuccessful) {
    return (
      <div className='d-flex justify-content-center'>
        <Lottie animation={paymentSuccessAnimation} height={300} width={300}></Lottie>
      </div>
    );
  }
  if (currentStep === 0)
    return <UpgradeModalStep3 isModalShown={isModalShown} isFromUpgradeModal={false} />;
  if (currentStep === 1) return <UpgradeModalStep2 isModalShown={isModalShown} />;
  if (currentStep === 2)
    return <UpgradeModalStep4 isModalShown={isModalShown} isFromUpgradeModal={false} />;
  return <>Step not implemented</>;
};

const ExtrasModalContext = createContext<{ handleShowModal: (extra: ProfileExtraModule) => void }>({
  handleShowModal: () => {},
});
export const useExtrasModal = () => useContext(ExtrasModalContext);

export const ExtrasModalProvider: FC = ({ children }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const isMd = useMediaQuery('(min-width: 768px)');
  const [currentStep, setCurrentStep] = useState(0);
  const subscriptionExpiry = useSelector(
    (state: RootState) => state.auth.user?.subscription_expiry
  );
  const taxNumber = useSelector(
    (state: RootState) => state.auth.user?.current_company?.setting?.tax_number
  );
  const currentUser = useSelector((state: RootState) => state.auth.user?.id);
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  const navigate = useNavigate();
  const handleHideModal = () => {
    setIsModalShown(false);
    if (currentStep === 2) {
      navigate('/profile/susbcriptions');
    }
    setCurrentStep(0);
  };
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultExtrasValues,
  });
  const queryClient = useQueryClient();
  const {
    resetForm,
    setPaymentAmount,
    paymentId,
    setPaymentId,
    setIsPaymentSuccessful,
    methods: bankTransferMethods,
  } = useBankTransferContext();

  const handleShowModal = (extra: ProfileExtraModule) => {
    setIsModalShown(true);
    methods.reset(
      {
        ...defaultExtrasValues,
        taxNumber,
        address: currentCompany?.setting?.address
          ? `${currentCompany?.setting?.address || ''}, ${currentCompany?.setting?.region || ''}, ${
              currentCompany?.setting?.country || ''
            }, ${currentCompany?.setting?.zip_code || ''}`
          : '',
        extra_modules: [extra],
        period: Math.max(
          roundedDifferenceInMonths(new Date(), new Date(subscriptionExpiry || Date.now())),
          1
        ),
      },
      {
        keepValues: false,
        keepTouched: false,
        keepErrors: false,
        keepIsValid: false,
      }
    );
    resetForm();
    setPaymentAmount(undefined);
    setPaymentId(undefined);
    setIsPaymentSuccessful(false);
  };
  const content = useMemo(
    () => <ExtraModalContent isModalShown={isModalShown} currentStep={currentStep} />,
    [isModalShown, currentStep]
  );
  const onExtraSubmit = async ({
    paymentType,
    paymentGateway,
    extra_modules,
    period,
    periodType,
    taxNumber,
    address,
  }: {
    paymentType: number | string;
    paymentGateway: number | string;
    extra_modules: ProfileExtraModule[];
    period: number;
    periodType: string;
    taxNumber: string;
    address: string;
  }) => {
    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
      return;
    }
    const { data } = await getLinkPayment({
      paymentType,
      paymentGateway,
      extrasModules: extra_modules.map((extra) => extra.id),
      type: 1,
      address: address || '',
      period: period || 1,
      periodType: periodType || 'YEARLY',
      coupon: '',
      packSms: 0,
      extraCompany: 0,
      extraUser: 0,
      bankTransferNumber: '',
      user: currentUser,
      plan: undefined,
      currency: process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
      taxNumber,
    });
    queryClient.invalidateQueries(['subscriptions']);
    if (+paymentType === 2) {
      setCurrentStep((step) => step + 1);
      setPaymentAmount({
        exclTaxes: data?.amount_ht || 0,
        inclTaxes: data?.amount_ttc || 0,
        currency: data?.currency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
      });
      setPaymentId(data?.id);
    } else {
      window.location.href = data.message;
    }
  };

  const onPaymentTranferSubmit = async (values: FieldValues) => {
    const formData = new FormData();
    formData.append('bankTransferNumber', values.reference || '');
    formData.append('file', values.file || '');
    try {
      await delayPromise(
        axios.post(`/secure/user/purchases/edit/${paymentId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
        1500
      );
      setIsPaymentSuccessful(true);
      queryClient.invalidateQueries(['subscriptions']);
      setTimeout(() => {
        navigate('/profile/susbcriptions');
        handleHideModal();
      }, 1500);
    } catch {
      setIsPaymentSuccessful(false);
    }
  };

  return (
    <ExtrasModalContext.Provider value={{ handleShowModal }}>
      {children}

      <FormProvider {...methods}>
        {isMd ? (
          <Modal
            size='lg'
            className='upgrade-modal'
            show={isModalShown}
            onHide={handleHideModal}
            centered>
            <div className='upgrade-modal-header'>
              {/* <div> */}
              <span className='upgrade-modal-title'>
                <FormattedMessage id='PROFILE.EXTRA' />
              </span>
              {/* </div> */}
              <div className='upgrade-modal-toolbar'>
                <button className='btn btn-light' onClick={handleHideModal}>
                  <FormattedMessage id='FORM.CLOSE' />
                </button>
                <button
                  className='btn btn-primary'
                  onClick={
                    currentStep === 2
                      ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                      : methods.handleSubmit(onExtraSubmit)
                  }>
                  <FormattedMessage id={currentStep === 0 ? 'FORM.CONTINUE' : 'COMMON.CONFIRM'} />
                </button>
              </div>
            </div>
            <form
              className='upgrade-modal-body d-flex flex-column gap-6'
              onSubmit={
                currentStep === 2
                  ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                  : methods.handleSubmit(onExtraSubmit)
              }>
              {content}
            </form>
          </Modal>
        ) : (
          <Drawer open={isModalShown} onClose={handleHideModal}>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>
                  <FormattedMessage id='PROFILE.EXTRA' />
                </DrawerTitle>
              </DrawerHeader>
              <form
                className='upgrade-modal-body d-flex flex-column gap-6'
                onSubmit={
                  currentStep === 2
                    ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                    : methods.handleSubmit(onExtraSubmit)
                }>
                {content}
              </form>
              <DrawerFooter className='px-4'>
                <button
                  className='btn btn-primary w-100'
                  onClick={
                    currentStep === 2
                      ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                      : methods.handleSubmit(onExtraSubmit)
                  }>
                  <FormattedMessage id={currentStep === 0 ? 'FORM.CONTINUE' : 'COMMON.CONFIRM'} />
                </button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )}
      </FormProvider>
    </ExtrasModalContext.Provider>
  );
};
