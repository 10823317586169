import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { RootState } from '../../../setup/Store';

export type CurrencyFormatterProps = Omit<
  React.ComponentProps<typeof FormattedNumber>,
  'children'
> & {
  amount: number | string;
  value?: number;
};
export default function CurrencyFormatter({
  amount,
  currency,
  style = 'currency',
  ...props
}: Partial<CurrencyFormatterProps>) {
  const defaultCurrency = useSelector(
    (state: RootState) => state.auth.user?.current_company?.setting?.default_currency
  );
  return (
    <FormattedNumber
      style={style}
      currency={currency || defaultCurrency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND'}
      {...props}
      value={!amount || Number.isNaN(+amount) ? 0 : +amount}
    />
  );
}
