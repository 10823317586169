import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useProfileExtrasQuery from '../../../hooks/queryHooks/useProfileExtrasQuery';
import { useUserPricesQuery } from '../../../hooks/queryHooks/useUserPricesQuery';
import { ProfileExtraModule } from '../../../models/ProfileExtras';
import { UserPlan } from '../../../models/UserPlan';
import { getArrayFromObject, getTaxStamp } from '../../../utils/utils';
import StyledCurrency from '../../StyledCurrency/StyledCurrency';

function UpgradeModalDetailsTotalInclTaxes({ isModalShown = false, isFromUpgradeModal = true }) {
  const plan: UserPlan = useWatch({ name: 'plan' });
  const { data: userPrices } = useUserPricesQuery({
    shouldFetch: isModalShown,
    priceType: 'YEARLY',
  });
  const minCompanies = useMemo(
    () => +(plan?.extra_company || 0) + +(plan?.max_company || 0),
    [plan]
  );
  const period = useWatch({ name: 'period', defaultValue: 12 });
  const companiesCount = useWatch({ name: 'companies', exact: true });
  const extraCompany = useWatch({ name: 'extra_company' });
  const extraUser = useWatch({ name: 'extra_user' });
  const extraModules: ProfileExtraModule[] = useWatch({ name: 'extra_modules', defaultValue: [] });
  const companyPrice = useMemo(() => {
    if (!userPrices) return 0;
    return userPrices.find((price) => price.code === 'EXTRA_COMPANY')?.amount_ttc || 0;
  }, [userPrices]);
  const { data: extrasData } = useProfileExtrasQuery(isModalShown);
  const extrasList = useMemo(() => {
    const extras = getArrayFromObject(extraModules || []).map(
      (extraModule) =>
        (extrasData || [])?.find((extra) => extra.id === extraModule?.id) || extraModule
    );
    return extras;
  }, [extrasData, extraModules]);

  const totalPrice = useMemo(() => {
    const pricePerYear = !plan ? 0 : plan?.price_per_year_ttc || 0;
    const companiesPrices =
      (!isFromUpgradeModal ? extraCompany || 0 : (companiesCount || 1) - minCompanies) *
      companyPrice *
      period;
    const extraPrices = extrasList
      .map((extraModule) => (+extraModule.price_ttc || 0) * period)
      .reduce((a, b) => a + b, 0);
    return pricePerYear + companiesPrices + extraPrices + getTaxStamp();
  }, [plan, companiesCount, minCompanies, extrasList, companyPrice, period]);
  return (
    <div className='upgrade-modal-total-container'>
      <div className='upgrade-modal-total-title'>
        <FormattedMessage id='DOCUMENT.FIELDS.INCL-TAXES-TOTAL' />
      </div>
      <StyledCurrency
        amountClassName='upgrade-modal-total-amount'
        supClassName='upgrade-modal-total-currency'
        containerClassName='d-flex align-items-center gap-1 justify-content-end'
        amount={totalPrice || 0}
        currency={process.env.REACT_APP_DEFAULT_CURRENCY}
      />
    </div>
  );
}

export default UpgradeModalDetailsTotalInclTaxes;
