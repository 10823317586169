import { differenceInDays, differenceInMonths, isBefore } from 'date-fns';
import { promiseDelay } from './configs';

export const hex2rgba = (hex: String, alpha = 1) => {
  if (hex) {
    const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
    return `rgba(${r},${g},${b},${alpha})`;
  }
  return `rgba(255,255,255,${alpha})`;
};
export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const delayPromise = <T>(promise: Promise<T>, delay = promiseDelay): Promise<T> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      promise.then((result) => resolve(result)).catch((err) => reject(err));
    }, delay);
  });
};

export const pickFields = (obj: any, fields: string[]) => {
  return fields.reduce((acc, curr) => {
    return obj.hasOwnProperty(curr) ? { ...acc, [curr]: obj[curr] } : acc;
  }, {});
};
export const webSitePattern =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;

export const getLastDayOfMonth = (date?: Date) => {
  const lastDay = new Date(
    date?.getFullYear() || new Date().getFullYear(),
    date?.getMonth() || new Date().getMonth() + 1,
    0
  );
  return lastDay;
};

export const getFirstDayOfMonth = (date?: Date) => {
  const firstDay = new Date(
    date?.getFullYear() || new Date().getFullYear(),
    date?.getMonth() || new Date().getMonth(),
    1
  );
  return firstDay;
};

export const validateSWIFT = (swift: string) => {
  if (swift) {
    const reg = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/;
    return reg.test(swift);
  }
};

// groupBy
export const groupBy = <T>(xs: any[], key: string): { [key: string]: T[] } => {
  if (!xs) return {};
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const getColorFromRgb = (rgb: string) => {
  if (rgb) {
    const [r, g, b] = rgb.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];
    const y = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return y > 186 ? '#000' : '#fff';
  }
  return '#000';
};

export const sortArray = <T extends object>(arr: T[], keys: (keyof T)[]) => {
  return arr.sort((a, b) => {
    const aKeys = keys.map((key) => a[key]);
    const bKeys: unknown[] = keys.map((key) => b[key]);
    return aKeys.join('') > bKeys.join('') ? 1 : -1;
  });
};
// object to form data
export const objectToFormData = (obj: any) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });
  return formData;
};
// isTruthy array and not undefined or null
export const isTruthyArray = (arr: any[]) => {
  if (arr) {
    return arr.length > 0;
  }
  return false;
};

export const replaceUndefined = (
  entity: any,
  detailedReplacement: { [key: string]: any } = {},
  defaultReplacement = ''
) => {
  return Object.keys(entity).reduce(
    (previous, current) => ({
      ...previous,
      [current]:
        entity[current] === undefined || entity[current] === null || entity[current] === ''
          ? detailedReplacement[current] || defaultReplacement
          : entity[current],
    }),
    {}
  );
};

export const removeFields = (object: any, keys: string[]) => {
  return Object.keys(object).reduce(
    (prev, current) => (keys.includes(current) ? prev : { ...prev, [current]: object[current] }),
    {}
  );
};

// export const removeFields = <T extends Record<string, unknown>>(
//   originalObject: T,
//   keys: Array<keyof T>
// ): Omit<T, (typeof keys)[number]> => {
//   return Object.keys(originalObject).reduce((prev, current) => {
//     if (!keys.includes(current)) {
//       return { ...prev, [current]: originalObject[current] };
//     }
//     return prev;
//   }, {} as T);
// };
// type GroupedData<T> = Record<string, ReadonlyArray<T>>;

type GroupedData<T> = Record<string, ReadonlyArray<T>>;

export function groupByV2<T extends Record<string, any>>(
  array: ReadonlyArray<T>,
  key: keyof T
): GroupedData<T> {
  return array.reduce((result: GroupedData<T>, currentValue) => {
    const groupKey = currentValue[key] as unknown as string; // Cast to string type
    return {
      ...result,
      [groupKey]: [...(result[groupKey] || []), currentValue],
    };
  }, {});
}
export function parseNumber(input: string | number, alt = 0): number {
  const parsedValue = Number(input);
  if (isNaN(parsedValue)) {
    return alt;
  }
  return parsedValue;
}

export const getDateBeforeMax = (date: Date, maxDate?: Date | null | undefined): Date => {
  if (maxDate === null || maxDate === undefined || isBefore(date, maxDate)) {
    return date;
  }
  return maxDate;
};
export const capitalizeWords = (str: string = ''): string => {
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    // Capitalize the first letter of each word
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a string
  const capitalizedStr = capitalizedWords.join(' ');

  return capitalizedStr;
};

export const getCurrencyPrecision = (currencyCode: string) => {
  try {
    const numberFormat = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
    });

    const parts = numberFormat.formatToParts(1.12345);
    const fractionPart = parts.find((part) => part.type === 'fraction');

    if (fractionPart) {
      return fractionPart.value.length;
    }

    return 0; // If no fraction part found, assume precision of 0
  } catch (error) {
    return 0; // Default to precision of 0 in case of any errors
  }
};

export function roundedDifferenceInMonths(startDate: Date | number, endDate: Date | number) {
  // Get the difference in days
  const daysDifference = differenceInDays(endDate, startDate);

  // Calculate the approximate difference in months (30.44 is the average number of days in a month)
  const monthsDifference = daysDifference / 30.44;

  // Round to the nearest month
  return Math.round(monthsDifference);
}

export const getArrayFromObject = <T>(data: T[] | { [key: string]: T } | undefined): T[] => {
  if (Array.isArray(data)) return data;
  if (data) return Object.values(data);
  return [];
};

export const getTaxStamp = (): number => {
  const taxStamp = Number(process.env.REACT_APP_TAX_STAMP);
  return Number.isNaN(taxStamp) ? 0 : taxStamp;
};
