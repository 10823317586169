import axios from 'axios';
import { TicketDetailsReponse } from '../../../app/models/ticketDetailReponse';
import { TicketsReponse } from '../../../app/models/ticketsResponse';

export const getTickets = (payload: { status?: number }) => {
  //add query params page and limit and status
  return axios.get<TicketsReponse>('/secure/tickets', { params: payload });
};

export const getTicket = (id: number) => {
  return axios.get<TicketDetailsReponse>(`/secure/tickets/ticket/${id}`);
};

export const addTicket = (data: any, files: any[]) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  files.forEach((file, index) => {
    formData.append(`attachments[${index + 1}]`, file);
  });
  return axios.post(`/secure/tickets/create-ticket`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
