import clsx from 'clsx';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ProfileFeature } from '../../../models/UserPlan';
import { getArrayFromObject } from '../../../utils/utils';

const UpgradeModalOptions = () => {
  const features: ProfileFeature[] = useWatch({ name: 'plan.features', defaultValue: [] });
  const featureArray = useMemo(() => getArrayFromObject(features), [features]);

  const { firstHalf, secondHalf } = useMemo(() => {
    return {
      firstHalf: featureArray?.slice(0, Math.ceil(featureArray.length / 2)) || [],

      secondHalf: featureArray?.slice(Math.ceil(featureArray.length / 2)) || [],
    };
  }, [features]);
  return featureArray?.length ? (
    <div className='upgrade-modal-options flex-column gap-4 flex-md-row gap-md-10'>
      <div className='d-flex flex-column gap-4 flex-grow-1'>
        {firstHalf.map((feature, index) => (
          <div className={clsx('upgrade-modal-option', feature.enabled && 'enabled')} key={index}>
            <div className='upgrade-modal-option-title'>
              <FormattedMessage id={`FEATURE.${feature.name}`} defaultMessage={feature.name} />
            </div>
            <i
              className={clsx(
                'fa-solid',
                feature.enabled ? 'fa-circle-check' : 'fa-circle-xmark',
                'upgrade-modal-option-icon'
              )}></i>
          </div>
        ))}
      </div>
      <div className='d-flex flex-column gap-4 flex-grow-1'>
        {secondHalf.map((feature, index) => (
          <div className={clsx('upgrade-modal-option', feature.enabled && 'enabled')} key={index}>
            <div className='upgrade-modal-option-title'>
              <FormattedMessage id={`FEATURE.${feature.name}`} defaultMessage={feature.name} />
            </div>
            <i
              className={clsx(
                'fa-solid',
                feature.enabled ? 'fa-circle-check' : 'fa-circle-xmark',
                'upgrade-modal-option-icon'
              )}></i>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default UpgradeModalOptions;
