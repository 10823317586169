import clsx from 'clsx';
import { useMemo } from 'react';
import { Tooltip } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import useProfileExtrasQuery from '../../../hooks/queryHooks/useProfileExtrasQuery';
import { ProfileExtraModule } from '../../../models/ProfileExtras';
import { getArrayFromObject } from '../../../utils/utils';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import OverlayTrigger from '../../OverlayTrigger/OverlayTrigger';

const UpgradeModalMyExtras = ({ isModalShown = false }) => {
  const moduleSubscriptions = useSelector(
    (state: RootState) => state.auth.user?.current_company.module_subscriptions
  );
  const { data: extrasData } = useProfileExtrasQuery(isModalShown);
  const extrasList = useMemo(() => {
    const extras = getArrayFromObject(moduleSubscriptions || [])
      .filter((susbcription) => !susbcription?.is_free)
      .map(
        (subscription) =>
          (extrasData || [])?.find((extra) => extra.id === subscription?.extras_module?.id) ||
          subscription.extras_module
      )
      .reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return [...acc, current];
        } else {
          return acc;
        }
      }, [] as ProfileExtraModule[]);
    return extras;
  }, [moduleSubscriptions, extrasData]);

  return (
    <>
      {extrasList?.length > 0 && (
        <div className='d-flex align-items-center gap-3'>
          <div className='upgrade-modal-details-title'>
            <FormattedMessage id='UPGRADE_MODAL.NON_INCLUDED_EXTRAS.TEXT' />
          </div>
          <OverlayTrigger
            placement='auto'
            overlay={
              <Tooltip>
                <FormattedMessage id='UPGRADE_MODAL.NON_INCLUDED_EXTRAS.TOOLTIP' />
              </Tooltip>
            }>
            <i className='fa-light fa-circle-info upgrade-modal-details-title'></i>
          </OverlayTrigger>
        </div>
      )}
      <Controller
        name='extra_modules'
        render={({ field }) => (
          <div className='row g-4'>
            {extrasList?.map((extra, index) => (
              <div key={index} className='col-md-3'>
                <label className={clsx('upgrade-modal-extra')}>
                  <span className='upgrade-modal-extra-title'>{extra.name}</span>
                  <div className='upgrade-modal-extra-amount'>
                    <CurrencyFormatter
                      amount={+(extra.price_ht || 0) * 12}
                      currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                      minimumFractionDigits={0}
                    />
                  </div>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input upgrade-modal-extra-switch'
                      type='checkbox'
                      value={extra.id}
                      name={field.name}
                      ref={field.ref}
                      checked={(field.value as ProfileExtraModule[])?.some(
                        (e) => e.id === extra.id
                      )}
                      onChange={() => {
                        if ((field.value as ProfileExtraModule[])?.some((e) => e.id === extra.id)) {
                          field.onChange(
                            (field.value as ProfileExtraModule[])?.filter((e) => e.id !== extra.id)
                          );
                        } else {
                          field.onChange([...(field.value as ProfileExtraModule[]), extra]);
                        }
                      }}
                    />
                  </div>
                </label>
              </div>
            ))}
          </div>
        )}
      />
    </>
  );
};

export default UpgradeModalMyExtras;
