import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import { UserPlan } from '../../../models/UserPlan';

const UpgradeModalEntreprises = () => {
  const plan: UserPlan = useWatch({ name: 'plan' });
  const { setValue, getValues } = useFormContext();
  const userPlan = useSelector((state: RootState) => state.auth.user?.current_plan);
  const minCompanies = useMemo(
    () =>
      Math.max(
        +(plan?.extra_company || 0) + +(plan.max_company || 0),
        +(userPlan?.max_company || 0) + +(userPlan?.extra_company || 0)
      ),
    [plan, userPlan]
  );
  useEffect(() => {
    const numberOfCompanies = getValues('companies');
    if (
      numberOfCompanies === undefined ||
      numberOfCompanies === null ||
      Number.isNaN(numberOfCompanies) ||
      numberOfCompanies < minCompanies
    ) {
      setValue('companies', minCompanies);
    }
  }, [minCompanies]);

  return (
    <div className='upgrade-modal-entreprise-container'>
      <div className='upgrade-modal-entreprise-title'>
        <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.COMPANIES' />
      </div>
      <Controller
        name='companies'
        render={({ field }) => (
          <div className='input-group input-group-solid mw-300px'>
            <span
              onClick={() => {
                if (!field.value || Number.isNaN(field.value)) field.onChange(minCompanies);
                else {
                  field.onChange(Math.max(field.value - 1, minCompanies));
                }
              }}
              className='input-group-text bg-secondary cursor-pointer'>
              <i className='fa-solid fa-minus'></i>
            </span>
            <input
              type='number'
              value={field.value}
              onBlur={field.onBlur}
              className='form-control text-center bg-secondary'
              name={field.name}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              min={minCompanies}
              onChange={(e) => {
                const val = +e.target.value;
                if (val >= minCompanies && Number.isInteger(val)) {
                  field.onChange(val);
                }
                // field.onChange(e);
              }}
            />
            <span
              onClick={() => {
                if (!field.value || Number.isNaN(field.value)) field.onChange(minCompanies);
                else {
                  field.onChange(+field.value + 1);
                }
              }}
              className='input-group-text bg-secondary cursor-pointer'>
              <i className='fa-solid fa-plus'></i>
            </span>
          </div>
        )}
      />
    </div>
  );
};

export default UpgradeModalEntreprises;
