import clsx from 'clsx';
import { Controller, useController, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import usePaymentGatewaysQuery from '../../../hooks/queryHooks/usePaymentGatewaysQuery';
import { UserPlan } from '../../../models/UserPlan';
import Image from '../../Image/Image';
import UpgradeModalDetailedInvoice from './upgradeModalDetailedInvoice';
import UpgradeModalDetailsTotalInclTaxes from './upgradeModalDetailsTotalInclTaxes';

function UpgradeModalStep3({ isModalShown = false, isFromUpgradeModal = true }) {
  const { data: paymentGateways } = usePaymentGatewaysQuery({ shouldFetch: isModalShown });
  const paymentGateway = useWatch({ name: 'paymentGateway' });
  const paymentType = useWatch({ name: 'paymentType' });
  const {
    field: { onChange: onChangePaymentGateway },
    fieldState: { error: paymentGatewayError, isTouched: paymentGatewayIsTouched },
  } = useController({
    name: 'paymentGateway',
    rules: { required: +paymentType === 1 && 'FORM.ERRORS.REQUIRED' },
  });
  return (
    <>
      <div className='upgrade-modal-details'>
        <UpgradeModalDetailedInvoice
          isModalShown={isModalShown}
          isFromUpgradeModal={isFromUpgradeModal}
        />
        <UpgradeModalDetailsTotalInclTaxes
          isModalShown={isModalShown}
          isFromUpgradeModal={isFromUpgradeModal}
        />
      </div>
      <Controller
        name='paymentType'
        render={({ field, fieldState }) => (
          <>
            <div className='row g-8'>
              {paymentGateways?.map((gateway) => (
                <div className='col-md-6'>
                  <label
                    key={gateway.id}
                    className={clsx(
                      'upgrade-modal-payment-gateway-card',
                      paymentGateway === gateway.id && field.value === 1 && 'enabled'
                    )}>
                    <input
                      type='radio'
                      name={field.name}
                      className='form-check-input'
                      onChange={() => {
                        field.onChange(1);
                        onChangePaymentGateway(gateway.id);
                      }}
                      checked={+paymentGateway === +gateway.id && +field.value === 1}
                    />
                    <div className='d-flex flex-column flex-grow-1 gap-2'>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='upgrade-modal-checkout-title'>
                          <FormattedMessage id='PRICING.PAYMENT.BANK_CARD' />
                          <Image
                            alt=''
                            src={gateway?.logo?.web_path || ''}
                            fallback={
                              <div className='badge badge-light-primary'>{gateway?.name}</div>
                            }
                            className={'upgrade-modal-payment-gateway-image'}
                          />
                        </div>
                      </div>
                      <div className='upgrade-modal-payment-gateway-subtitle'>
                        <FormattedMessage id='PRICING.PAYMENT.INSTANT_ACTIVATION' />
                      </div>
                    </div>
                  </label>
                </div>
              ))}
              <div className='col-md-6'>
                <label
                  className={clsx(
                    'upgrade-modal-payment-gateway-card',
                    +field.value === 2 && 'enabled'
                  )}>
                  <input
                    type='radio'
                    className='form-check-input'
                    name={field.name}
                    onChange={() => {
                      field.onChange(2);
                      onChangePaymentGateway(undefined);
                    }}
                    checked={+field.value === 2}
                  />
                  <div className='d-flex flex-column flex-grow-1 gap-2'>
                    <div className='d-flex gap-2 align-items-center'>
                      <div className='upgrade-modal-checkout-title'>
                        <FormattedMessage id='PRICING.PAYMENT.TRANSFERT_BANK' />
                      </div>
                    </div>
                    <div className='upgrade-modal-payment-gateway-subtitle'>
                      <FormattedMessage id='PRICING.PAYMENT.DELAI_ACTIVATION' />
                    </div>
                  </div>
                </label>
              </div>
            </div>
            {((paymentGatewayError && paymentGatewayIsTouched) ||
              (fieldState.error && fieldState.isTouched)) && (
              <div className='text-danger'>
                <span role='alert'>
                  <FormattedMessage
                    id={fieldState?.error?.message || paymentGatewayError?.message}
                    values={{
                      field: <FormattedMessage id='PAYMENT.TYPE' />,
                    }}
                  />
                </span>
              </div>
            )}
          </>
        )}
      />
      {+paymentType === 2 && (
        <div className='upgrade-modal-details'>
          <div className='row g-4'>
            <div className='col-md-6'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage id='BANK.BANK' />
              </div>
            </div>
            <div className='col-md-6 text-md-end'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage
                  id={`BANK.${process.env.REACT_APP_BANK_DETAILS_BANK_NAME}`}
                  defaultMessage={process.env.REACT_APP_BANK_DETAILS_BANK_NAME || '--'}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage id='PRICING.BANK_PAYMENT.RIB' />
              </div>
            </div>
            <div className='col-md-6 text-md-end'>
              <div className='upgrade-modal-bank-information '>
                {process.env.REACT_APP_BANK_DETAILS_RIB}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage id='PRICING.BANK_PAYMENT.IBAN' />
              </div>
            </div>
            <div className='col-md-6 text-md-end'>
              <div className='upgrade-modal-bank-information '>
                {process.env.REACT_APP_BANK_DETAILS_IBAN}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage id='BANKING.SWIFT' />
              </div>
            </div>
            <div className='col-md-6 text-md-end'>
              <div className='upgrade-modal-bank-information '>
                {process.env.REACT_APP_BANK_DETAILS_SWIFT}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage id='PRICING.BANK_PAYMENT.HOLDER' />
              </div>
            </div>
            <div className='col-md-6 text-md-end'>
              <div className='upgrade-modal-bank-information '>
                <FormattedMessage
                  id={`BANK.${process.env.REACT_APP_BANK_DETAILS_ACCOUNT_HOLDER}`}
                  defaultMessage={process.env.REACT_APP_BANK_DETAILS_ACCOUNT_HOLDER || '--'}
                />
              </div>
            </div>
            {/* <button
              type='button'
              className='btn btn-light btn-outline btn-outline-primary btn-sm btn-flex justify-content-center gap-4'>
              Copier les coordonnées bancaires
              <i className='fa-light fa-copy' />
            </button> */}
          </div>
        </div>
      )}
    </>
  );
}

export default UpgradeModalStep3;
