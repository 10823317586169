import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import useProfileExtrasQuery from '../../../hooks/queryHooks/useProfileExtrasQuery';
import { useUserPricesQuery } from '../../../hooks/queryHooks/useUserPricesQuery';
import { ProfileExtraModule } from '../../../models/ProfileExtras';
import { getArrayFromObject } from '../../../utils/utils';
import StyledCurrency from '../../StyledCurrency/StyledCurrency';

const UpgradeModalDetailsTotal = ({ isModalShown = false }) => {
  const plan = useWatch({ name: 'plan' });
  const { getValues } = useFormContext();
  const { data: userPrices } = useUserPricesQuery({
    shouldFetch: isModalShown,
    priceType: 'YEARLY',
  });
  const userPlan = useSelector((state: RootState) => state.auth.user?.current_plan);

  const minCompanies = useMemo(
    () =>
      Math.max(
        +(plan?.extra_company || 0) + +(plan.max_company || 0),
        +(userPlan?.max_company || 0) + +(userPlan?.extra_company || 0)
      ),
    [plan, userPlan]
  );

  const companiesCount = useWatch({ name: 'companies', exact: true });
  const extraModules: ProfileExtraModule[] = useWatch({ name: 'extra_modules', defaultValue: [] });
  const companyPrice = useMemo(() => {
    if (!userPrices) return 0;
    return userPrices.find((price) => price.code === 'EXTRA_COMPANY')?.amount_ht || 0;
  }, [userPrices]);
  const { data: extrasData } = useProfileExtrasQuery(isModalShown);
  const extrasList = useMemo(() => {
    const extras = getArrayFromObject(extraModules || []).map(
      (extraModule) =>
        (extrasData || [])?.find((extra) => extra.id === extraModule?.id) || extraModule
    );
    return extras;
  }, [extrasData, extraModules]);
  const totalPrice = useMemo(() => {
    const pricePerYear = plan?.price_per_year || 0;
    const companiesPrices =
      ((getValues('companies') || 1) - minCompanies) * (companyPrice || 0) * 12;
    const extraPrices = extrasList
      .map((extraModule) => (+extraModule.price_ht || 0) * 12)
      .reduce((a, b) => a + b, 0);
    return pricePerYear + companiesPrices + extraPrices;
  }, [plan, companiesCount, minCompanies, extrasList, companyPrice]);
  return (
    <div className='upgrade-modal-total-container'>
      <div className='upgrade-modal-total-title'>
        <FormattedMessage id='PRICING.STEP1.PLAN.EXTRAT.TOTAL' />
      </div>
      <StyledCurrency
        amountClassName='upgrade-modal-total-amount'
        supClassName='upgrade-modal-total-currency'
        containerClassName='d-flex align-items-center gap-1 justify-content-end'
        amount={totalPrice || 0}
        currency={process.env.REACT_APP_DEFAULT_CURRENCY}
      />
    </div>
  );
};

export default UpgradeModalDetailsTotal;
