import { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import BoardTickets from './BoardTickets/BoardTickets';
import DetailsTicket from './DetailsTicket/DetailsTicket';

export default function TicketsRoutes() {
  return (
    <Routes>
      <Route element={<TicketsMasterLayout />}>
        <Route path='/' element={<BoardTickets />} />
        <Route path='/ticket/:id' element={<DetailsTicket />} />
      </Route>
    </Routes>
  );
}
const TicketsMasterLayout = () => {
  const [hasCheckedTickets, setHasCheckedTickets] = useLocalStorage('has_checked_Tickets', false);
  useEffect(() => {
    !hasCheckedTickets && setTimeout(() => setHasCheckedTickets(true), 1000);
  }, []);
  return <Outlet />;
};
