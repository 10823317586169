import { useQuery } from '@tanstack/react-query';
import { getUserPlan } from '../../../setup/redux/effects/UtilsEffects';
import { UserPlan } from '../../models/UserPlan';

const userPlansQueryKey = ['userPlans'];

const useUserPlansQuery = (shouldFetch = false) => {
  return useQuery({
    queryKey: [...userPlansQueryKey, shouldFetch],
    queryFn: async () => {
      if (!shouldFetch) return [] as UserPlan[];
      const { data } = await getUserPlan();
      return data;
    },
  });
};

export default useUserPlansQuery;
