import { useQuery } from '@tanstack/react-query';
import { getTicket, getTickets } from '../../../setup/redux/effects/TicketsEffects';

const TICKETS_QUERY_KEY = ['tickets'];
const TICKETS_LIST_QUERY_KEY = [...TICKETS_QUERY_KEY, 'list'];
const TICKETS_DETAILS_QUERY_KEY = [...TICKETS_QUERY_KEY, 'details'];

const useTickets = (ticketsQuery: { status?: number }) => {
  const query = useQuery({
    queryKey: [...TICKETS_LIST_QUERY_KEY, ticketsQuery.status],
    queryFn: () => getTickets(ticketsQuery).then((res) => res.data),
  });
  return query;
};

const useTicket = (id: number) => {
  const query = useQuery({
    queryKey: [...TICKETS_DETAILS_QUERY_KEY, id],
    queryFn: () => getTicket(id).then((res) => res.data),
  });
  return query;
};

export {
  useTickets,
  useTicket,
  TICKETS_QUERY_KEY,
  TICKETS_LIST_QUERY_KEY,
  TICKETS_DETAILS_QUERY_KEY,
};
