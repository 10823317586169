import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SwSubscription } from '../../../models/SubscriptionsResponse';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import StyledCurrency from '../../StyledCurrency/StyledCurrency';
import { useBankTransferContext } from '../providers/bankTransferPaymentProvider';
import UpgradeModalDetailedInvoice from './upgradeModalDetailedInvoice';
interface DropzoneProps {
  onChange: (file: File | undefined) => void;
  onBlur: () => void;
  name: string;
  value: File | undefined;
  onFileChange?: (file: File | undefined) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onChange, onBlur, name, onFileChange, value }) => {
  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        onChange(acceptedFiles[0]);
        onFileChange?.(acceptedFiles[0]);
      }
    },
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxFiles: 1,
  });

  const removeFile = () => {
    onChange(undefined);
    onFileChange?.(undefined);
  };

  return (
    <>
      <div
        {...getRootProps()}
        className={clsx(
          'd-flex flex-column bg-light-primary align-items-center justify-content-center border border-2 border-dashed rounded p-5 min-h-125px text-center cursor-pointer'
        )}
        style={{
          borderColor: isDragActive ? 'var(--kt-primary)' : 'var(--kt-gray-700)',
        }}>
        <input {...getInputProps({ onBlur, name, ref: inputRef })} />
        {value ? (
          <div className='d-flex align-items-center gap-4'>
            <div>
              <p className='mb-1'>{value.name}</p>
              <p className='text-muted small mb-0'>{(value.size / 1024).toFixed(2)} KB</p>
            </div>
            <button
              type='button'
              onClick={(e) => {
                e.stopPropagation();
                removeFile();
              }}
              className='btn btn-sm btn-icon btn-light-danger'>
              <i className='fa-light fa-trash'></i>
            </button>
          </div>
        ) : isDragActive ? (
          <p className='text-primary mb-0'>Déposez le fichier ici...</p>
        ) : (
          <div className='d-flex flex-column gap-8 align-items-center justify-content-center'>
            <div className='d-flex align-items-center justify-content-center h-45px w-45px bg-light rounded-circle border border-1 border-gray-400'>
              <i className='fa-light fa-up-from-line text-primary'></i>
            </div>
            <div>
              <p>
                <span className='text-primary'>Cliquez pour télécharger</span> ou glisser-déposer
              </p>
              <p className='text-gray-800 small'>PDF, PNG, ou JPG</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
function UpgradeModalStep4({
  isModalShown = false,
  subscription,
  isFromUpgradeModal = true,
}: {
  isModalShown?: boolean;
  subscription?: SwSubscription;
  isFromUpgradeModal?: boolean;
}) {
  const { methods, paymentAmount } = useBankTransferContext();
  return (
    <>
      {(!subscription || (subscription?.status === 0 && subscription?.payment_type === 2)) && (
        <div className='alert alert-primary d-flex align-items-center p-5'>
          <i className='fa-light fa-circle-info text-primary me-4'></i>
          <span>
            <FormattedMessage
              id='PRICING.BANK_TRANSFER.MESSAGE'
              values={{
                amount: (
                  <strong>
                    <CurrencyFormatter
                      amount={paymentAmount?.inclTaxes || 0}
                      currency={subscription?.currency || process.env.REACT_APP_DEFAULT_CURRENCY}
                    />
                  </strong>
                ),
              }}
            />
          </span>
        </div>
      )}
      <div className='upgrade-modal-details'>
        <UpgradeModalDetailedInvoice
          isModalShown={isModalShown}
          isFromUpgradeModal={isFromUpgradeModal}
        />
        <div className='upgrade-modal-total-container'>
          <div className='upgrade-modal-total-title'>
            <FormattedMessage id='DOCUMENT.FIELDS.INCL-TAXES-TOTAL' />
          </div>
          <StyledCurrency
            amountClassName='upgrade-modal-total-amount'
            supClassName='upgrade-modal-total-currency'
            containerClassName='d-flex align-items-center gap-1 justify-content-end'
            amount={paymentAmount?.inclTaxes || 0}
            currency={process.env.REACT_APP_DEFAULT_CURRENCY}
          />
        </div>
      </div>
      {(!subscription || (subscription?.status === 0 && subscription?.payment_type === 2)) && (
        <div className='row g-4'>
          <Controller
            control={methods.control}
            name='reference'
            render={({ field, fieldState: { error } }) => (
              <div>
                <div className='form-label required'>
                  <FormattedMessage id='LIST_DOCUMENT.FIELD.REFRENCE' />
                </div>
                <input type='text' className='form-control form-control-solid' {...field} />
                {error && <div className='text-danger'>{error.message}</div>}
              </div>
            )}
          />
          <Controller
            name='file'
            control={methods.control}
            rules={{ required: 'File is required' }}
            render={({ field: { name, onChange, value, onBlur }, fieldState: { error } }) => (
              <div>
                <div className='form-label required'>
                  <FormattedMessage id='PRICING.BANK_PAYMENT.SEND_ATTACHMENT.TITLE' />
                </div>
                <Dropzone
                  name={name}
                  value={value}
                  onFileChange={onChange}
                  onBlur={onBlur}
                  onChange={onChange}
                />
                {error && <div className='text-danger'>{error.message}</div>}
              </div>
            )}
          />
        </div>
      )}
    </>
  );
}

export default UpgradeModalStep4;
