import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import BankTransferPaymentProvider from '../../components/UpgradeModal/providers/bankTransferPaymentProvider';
import { ExtrasModalProvider } from '../../ModalContexts/ExtrasModalContext';
import { SuspensedView } from '../../routing/PrivateRoutes';
import { lazyWithRetry } from '../../utils/LazyWithRetry';

const ProfileNav = lazyWithRetry(() => import('./ProfileNav'));
const ProfileOverview = lazyWithRetry(() => import('./ProfileOverview'));
const ProfileSubscriptions = lazyWithRetry(() => import('./ProfileSubscriptions'));
const ProfileExtras = lazyWithRetry(() => import('./profileExtras'));

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route element={<ProfileMasterLayout />}>
        <Route
          path='/overview'
          element={
            <SuspensedView>
              <ProfileOverview />
            </SuspensedView>
          }
        />
        <Route
          path='/subscriptions'
          element={
            <SuspensedView>
              <ProfileSubscriptions />
            </SuspensedView>
          }
        />
        <Route
          path='/extras'
          element={
            <SuspensedView>
              <BankTransferPaymentProvider>
                <ExtrasModalProvider>
                  <ProfileExtras />
                </ExtrasModalProvider>
              </BankTransferPaymentProvider>
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/profile/overview' />} />
      </Route>
    </Routes>
  );
}

const ProfileMasterLayout = () => {
  return (
    <>
      <SuspensedView>
        <div className='sw-profile'>
          {/* <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            <ProfileDetails />
          </div> */}
          <ProfileNav />
          <div className='tab-content'>
            <div className='tab-pane fade active show' role='tabpanel'>
              <Outlet />
            </div>
          </div>
        </div>
      </SuspensedView>
    </>
  );
};
