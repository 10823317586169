import { useQuery } from '@tanstack/react-query';
import { getPlanPrice } from '../../../setup/redux/effects/UtilsEffects';
import { SubscriptionPrice } from '../../models/SubscriptionPrice';

export const userPricesQueryKey = ['userPrices'];

export const useUserPricesQuery = ({
  shouldFetch = false,
  priceType,
}: {
  shouldFetch: boolean;
  priceType: string;
}) =>
  useQuery({
    queryKey: [...userPricesQueryKey, shouldFetch, priceType.toUpperCase()],
    queryFn: async () => {
      if (!shouldFetch) return [] as SubscriptionPrice[];
      const { data } = await getPlanPrice(priceType);
      return data;
    },
  });
