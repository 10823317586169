import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPageData } from '../../../../setup/redux/reducers/PageReducer';
import SwTable from '../../../components/SwTable/SwTable';
import { TICKETS_QUERY_KEY, useTickets } from '../../../hooks/queryHooks/useTickets';
import { Priority, Ticket } from '../../../models/ticketsResponse';
import { DrawerComponent } from '../../../utils/ts/components';
import NewTicket from '../NewTicket/NewTicket';

const columnHelper = createColumnHelper<Ticket>();

const columns = [
  columnHelper.accessor('id', {
    cell: (info) => <span className='fw-bold fs-7'>{`#${info.getValue()}`}</span>,
    header: () => <FormattedMessage id='TICKETS.TICKET_NUMBER' />,
    size: 125,
  }),

  columnHelper.accessor('subject', {
    cell: (info) => <span className='fw-semibold fs-6'>{info.getValue()}</span>,
    header: () => <FormattedMessage id='TICKETS.SUBJECT' />,
    minSize: 250,
  }),
  columnHelper.accessor('agent', {
    cell: (info) => (
      <div className='d-flex align-items-center gap-4'>
        <div className='symbol symbol-30px'>
          <img src={info.getValue()?.smallThumbnail} alt='Agent thumbnail' />
        </div>
        <span>{info.getValue()?.name}</span>
      </div>
    ),
    header: () => (
      <span className='fs-7 fw-bold'>
        <FormattedMessage id='TICKETS.AGENT' />
      </span>
    ),
  }),
  columnHelper.accessor('priority', {
    cell: (info) => (
      <span className='badge badge-light' style={{ color: info.getValue().colorCode }}>
        <FormattedMessage
          id={`TICKETS.PRIORITY.${(info.getValue().code || '').toUpperCase()}`}
          defaultMessage={info.getValue().code || ''}
        />
      </span>
    ),
    header: () => (
      <span>
        <FormattedMessage id='TICKETS.PRIORITY.TITLE' />
      </span>
    ),
    size: 75,
  }),
  columnHelper.accessor('status', {
    cell: (info) => (
      <span className='badge badge-light' style={{ color: info.getValue().colorCode }}>
        <FormattedMessage
          id={`TICKETS.STATUS.${(info.getValue().code || '').toUpperCase()}`}
          defaultMessage={info.getValue().code || ''}
        />
      </span>
    ),
    header: () => (
      <span>
        <FormattedMessage id='TICKETS.STATUS.TITLE' />
      </span>
    ),
    size: 75,
  }),
  columnHelper.accessor('formatedCreatedAt', {
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => (
      <span>
        <FormattedMessage id='TICKETS.CREATED_AT' />
      </span>
    ),
    size: 125,
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => {
      const row = info.row.original;
      return (
        <div className='d-flex align-items-center justify-content-end me-2'>
          <Link to={`/tickets/ticket/${row.id}`} className='btn btn-light-primary btn-icon btn-sm'>
            <i className='fa-light fa-eye' />
          </Link>
        </div>
      );
    },
    header: () => (
      <span className='d-flex align-items-center justify-content-end me-2'>
        <FormattedMessage id='TABLE.ACTIONS' />
      </span>
    ),
    size: 75,
  }),
];

function BoardTickets() {
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [latestStatuses, setLatestStatuses] = useState<Priority[]>([]);

  const queryClient = useQueryClient();
  const { data, isLoading } = useTickets({
    status: selectedStatus !== 0 ? selectedStatus : undefined,
  });
  const statuses = data?.status || [];
  useEffect(() => {
    if (statuses?.length) {
      setLatestStatuses(statuses);
    }
  }, [statuses]);

  const tickets = data?.tickets || [];

  const refreshData = () => {
    // getData();
    queryClient.invalidateQueries(TICKETS_QUERY_KEY);
  };
  useEffect(() => {
    DrawerComponent.reinitialization();
    // getData();
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageData({
        title: <FormattedMessage id='TOPBAR.TICKETS' defaultMessage='Tickets' />,
        actionBar: 'noActions',
      })
    );
  }, [dispatch]);
  return (
    <>
      {/* begin::V2 */}
      <div className='row'>
        <div className='col-md-4 col-lg-3 col-xl-2'>
          <div className='card card-flush mb-0 d-flex flex-column gap-4 p-6'>
            <NewTicket afterSubmit={refreshData}>
              {(handleShowNewTicket) => (
                <button onClick={handleShowNewTicket} className='btn btn-primary fw-bold w-100'>
                  <FormattedMessage id='TICKETS.CREATE' />
                </button>
              )}
            </NewTicket>
            <hr />
            <div
              className={clsx(
                'btn text-start text-capitalize',
                !selectedStatus ? 'btn-primary' : 'btn-light'
              )}
              onClick={() => setSelectedStatus(0)}>
              <FormattedMessage id='TICKETS.STATUS.ALL' />
            </div>
            {latestStatuses.map((status) => (
              <div
                key={status.id}
                className={clsx(
                  'btn text-start text-capitalize',
                  selectedStatus === status.id ? 'btn-primary' : 'btn-light'
                )}
                onClick={() => setSelectedStatus(status.id)}>
                <FormattedMessage
                  id={`TICKETS.STATUS.${status.code.toUpperCase()}`}
                  defaultMessage={status.code}
                />
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-8 col-lg-9 col-xl-10'>
          <SwTable columns={columns} data={tickets} isLoading={isLoading} />
        </div>
      </div>
      {/* end::V2 */}
    </>
  );
}

export default BoardTickets;
