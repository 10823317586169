/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import { format } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';
import { ReactNode, useMemo, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { RootState } from '../../../../setup/Store';
import { toAbsoluteUrl } from '../../../helpers';

const CopyToClipboard = ({
  displayedText,
  copyText,
  icon,
}: {
  displayedText: string;
  copyText?: string;
  icon?: ReactNode;
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyText || displayedText);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      // Fallback for browsers that don't support clipboard API
      const textArea = document.createElement('textarea');
      textArea.value = copyText || displayedText;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        // console.error('Fallback failed to copy text: ', err);
      }
      document.body.removeChild(textArea);
    }
  };
  return (
    <div className='position-relative'>
      {icon && (
        <div className='position-absolute translate-middle-y top-50 start-0 ms-3'>{icon}</div>
      )}
      <input
        type='text'
        className={clsx('form-control form-control-solid', { 'ps-6': icon })}
        value={displayedText}
      />
      <OverlayTrigger
        placement='bottom'
        show={copied}
        overlay={<Tooltip>{copied ? 'Copied!' : 'Copy'}</Tooltip>}>
        <div
          className='position-absolute translate-middle-y top-50 end-0 me-3 cursor-pointer'
          onClick={handleCopy}>
          <i
            className={clsx('fa-light fs-1hx', {
              'fa-copy': !copied,
              'fa-check text-success': copied,
            })}></i>
        </div>
      </OverlayTrigger>
    </div>
  );
};
const SidebarFooter = () => {
  const date = useMemo(() => format(new Date(), 'yyyy-MM'), []);
  const [hasCheckedTickets] = useLocalStorage('has_checked_Tickets', false);
  const userReference = useSelector((state: RootState) => state.auth.user?.reference);
  const [isSupportModalShown, setIsSupportModalShown] = useState(false);

  const { phoneNumber, formattedPhoneNumber } = useMemo(() => {
    const num = '+21636018000';
    const parsedPhoneNumber = parsePhoneNumber(num);
    const formattedPhoneNumber = parsedPhoneNumber?.formatInternational() || num;
    return { formattedPhoneNumber, phoneNumber: num };
  }, []);

  const handleShowSupportModal = () => {
    setIsSupportModalShown(true);
  };
  const handleCloseSupportModal = () => {
    setIsSupportModalShown(false);
  };

  return (
    <>
      <div className='app-sidebar-footer' id='kt_app_sidebar_footer'>
        <div className='app-sidebar-footer-top'>
          <Link to={'/tickets'} className='btn btn-custom btn-flex'>
            <i className='fa-light fa-ticket'></i>
            <span className='btn-label'>
              <FormattedMessage id='TICKETS.CREATE' />
            </span>
            {!hasCheckedTickets && (
              <div className='badge badge-light-warning ms-auto'>
                <FormattedMessage id='COMMON.NEW' />
              </div>
            )}
          </Link>
        </div>
        <div className='app-sidebar-footer-top '>
          <a
            href={`https://calendly.com/oumaima-h/30min?month=${date}`}
            target='_blank'
            className='btn btn-custom'>
            <i className='fa-light fa-laptop'></i>
            <span className='btn-label'>
              <FormattedMessage id='SIDEMENU.FOOTER.DEMO' />
            </span>
          </a>
          <div className='separator'></div>
          <button className='btn btn-custom' onClick={handleShowSupportModal}>
            <i className='fa-light fa-headset'></i>
            <span className='btn-label'>
              <FormattedMessage id='SIDEMENU.FOOTER.SUPPORT' />
            </span>
          </button>
          <button className='btn btn-custom btn-footer-menu'>
            <i className='fa-light fa-ellipsis-vertical'></i>
          </button>
        </div>
        <div className='app-sidebar-footer-bottom'>
          <img
            className='footer-logo-full show-dark'
            src={toAbsoluteUrl('/media/logos/logo-swiver.svg')}
            alt=''
          />
          <img
            className='footer-logo-full show-light'
            src={toAbsoluteUrl('/media/logos/logo-swiver-dark.svg')}
            alt=''
          />
          <img
            className='footer-logo-minimized'
            src={toAbsoluteUrl('/media/logos/logo.svg')}
            alt=''
          />
          <div className='sw-version'>{userReference}</div>
        </div>
      </div>
      <Modal show={isSupportModalShown} onHide={handleCloseSupportModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='SIDEMENU.FOOTER.SUPPORT' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='row g-4 w-100'>
            <div>
              <div className='form-label'>Phone number</div>
              <CopyToClipboard
                displayedText={formattedPhoneNumber}
                copyText={phoneNumber}
                icon={<i className='fs-2hx fa-light fa-phone'></i>}
              />
            </div>
          </div> */}
          <div className='row g-4 w-100'>
            <div className='d-flex gap-7 align-items-center'>
              <div className='symbol symbol-circle symbol-100px'>
                <img src={toAbsoluteUrl('/media/logos/logo.svg')} alt='logo' />
              </div>
              <div className='d-flex flex-column gap-2'>
                <h3 className='mb-0'>
                  <FormattedMessage id='SUPPORT.TEAM' />
                </h3>
                <div className='d-flex align-items-center gap-2'>
                  <i className='fa-light  text-gray-700 fa-envelope fs-2' />
                  <span className='text-gray-700 text-hover-primary'>support@swiver.net</span>
                  <a
                    href='https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=support@swiver.net'
                    target='_blank'>
                    <i className='fa-light fa-paper-plane-top text-gray-700 text-hover-primary fs-2' />
                  </a>
                </div>
                <div className='d-flex align-items-center gap-2'>
                  <i className='fa-light text-gray-700 fa-phone fs-2' />
                  <span className='text-gray-700 text-hover-primary' dir='ltr'>
                    {formattedPhoneNumber}
                  </span>
                </div>
              </div>
            </div>
            <div className='bg-secondary rounded p-4'>
              <h4 className='mb-4'>
                <FormattedMessage id='SUPPORT.WORK_HOURS' />
              </h4>
              <h6>
                <FormattedMessage id='SUPPORT.REGULAR_HOURS.TITLE' />
              </h6>
              <p>
                <FormattedMessage id='SUPPORT.REGULAR_HOURS.WEEKDAYS' />
                <br />
                <FormattedMessage id='SUPPORT.REGULAR_HOURS.WEEKENDS' />
              </p>
              <h6>
                <FormattedMessage id='SUPPORT.SUMMER_HOURS.TITLE' />
              </h6>
              <p>
                <FormattedMessage id='SUPPORT.SUMMER_HOURS.WEEKDAYS' />
                <br />
                <FormattedMessage id='SUPPORT.SUMMER_HOURS.WEEKENDS' />
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { SidebarFooter };
