import { FC, ReactElement, useState } from 'react';

interface ImageProps {
  src: string;
  alt: string;
  fallback: ReactElement;
  className?: string;
  style?: React.CSSProperties;
}

const Image: FC<ImageProps> = ({ src, alt, fallback = <></>, className = '', style = {} }) => {
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
  };

  if (!src || imageError) {
    return fallback;
  }

  return (
    <img src={src} alt={alt} onError={handleError} className={className} style={{ ...style }} />
  );
};

export default Image;
