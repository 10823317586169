import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

const upgradeSteps = [
  'UPGRADE_MODAL.STEPS.STEP_1',
  'UPGRADE_MODAL.STEPS.STEP_2',
  'UPGRADE_MODAL.STEPS.STEP_3',
];

const UpgradeStepper = ({ currentStep }: { currentStep: number }) => {
  return (
    <div className='sw-step-indicator'>
      {upgradeSteps.map((step, index) => (
        <div
          className={clsx('sw-step', {
            active: index === currentStep,
            completed: index < currentStep,
          })}
          key={index}>
          <div className='sw-step-circle'>{index + 1}</div>
          {index < upgradeSteps.length - 1 && <div className='sw-step-connector'></div>}
          <div className='sw-step-title'>
            <FormattedMessage id={step} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default UpgradeStepper;
export { upgradeSteps };
