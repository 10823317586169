import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';
import useUserPlansQuery from '../../../hooks/queryHooks/useUserPlansQuery';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import Loader from '../../Loader/Loader';

function UpgradeModalPlansSelection({ isModalShown = false }) {
  const { data: userPlans, isLoading } = useUserPlansQuery(isModalShown);
  const { setValue, getValues } = useFormContext();
  const currentPlan = useSelector((state: RootState) => state.auth.user?.current_plan)!;
  const purchaseDate = useSelector((state: RootState) => state.auth.user?.purchase_date)!;
  const periodType = useWatch({ name: 'periodType' });
  const plans = useMemo(() => {
    const currentUserPlan = userPlans?.find((plan) => plan.id === currentPlan?.id) || currentPlan;
    const filtredPlans = userPlans?.filter(
      (plan) => !(plan.id === currentPlan?.id || (purchaseDate && plan.name === 'PROMO'))
    );
    return [currentUserPlan!, ...(filtredPlans || [])];
  }, [userPlans, currentPlan]);

  useEffect(() => {
    const plan = getValues('plan');
    if (!userPlans) return;
    if (plan?.id === currentPlan?.id) {
      const planData = userPlans?.find((plan) => plan.id === currentPlan?.id);
      if (planData) {
        setValue('plan', planData);
      }
    }
  }, [currentPlan, userPlans]);

  return (
    <Loader loading={isLoading}>
      <div className='row g-4 flex-nowrap flex-md-wrap overflow-auto'>
        <Controller
          name='plan'
          rules={{
            required: 'FORM.ERRORS.REQUIRED',
            validate: (value) => {
              if (
                !userPlans ||
                !userPlans?.some((p) => p.id === value?.id) ||
                (!!purchaseDate && value?.name === 'PROMO')
              ) {
                return 'FORM.ERRORS.INVALID_PLAN';
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <>
              {plans.map((plan, index) => (
                <label key={index} className='col-7 col-md-6 col-lg-4'>
                  <div
                    className={clsx('upgrade-plan-card', {
                      disabled:
                        !userPlans?.some((p) => p.id === plan?.id) ||
                        !!(purchaseDate && plan?.name === 'PROMO'),
                      selected: plan?.id === field?.value?.id,
                    })}>
                    <div className='upgrade-plan-card-content'>
                      <div className='d-flex gap-4 w-100'>
                        <div className='upgrade-plan-card-icon-container bg-light-warning'>
                          <i className='fa-regular fa-trophy text-warning'></i>
                        </div>
                        <span className='ms-auto'>
                          {plan?.id === currentPlan?.id && (
                            <div
                              className={clsx(
                                'badge',
                                plan?.id === field?.value?.id
                                  ? 'badge-light-primary'
                                  : 'badge-light'
                              )}>
                              <FormattedMessage id='UPGRADE_MODAL.CURRENT_PLAN' />
                            </div>
                          )}
                          <input
                            type='radio'
                            onChange={() => field.onChange(plan)}
                            value={plan?.id}
                            checked={field.value?.id === plan?.id}
                            name={field.name}
                            disabled={
                              !userPlans?.some((p) => p.id === plan?.id) ||
                              !!(purchaseDate && plan?.name === 'PROMO')
                            }
                            className={clsx(
                              'form-check-input',
                              plan?.id === currentPlan?.id && 'd-none'
                            )}
                          />
                        </span>
                      </div>
                      <div className='upgrade-plan-card-title-container'>
                        <div className='upgrade-plan-card-title'>
                          <FormattedMessage
                            id={`PLANS.${plan?.name}`}
                            defaultMessage={plan?.name}
                          />
                        </div>
                        <div className='upgrade-plan-card-subtitle'>
                          {plan?.name?.startsWith('FREE') ? (
                            'Abonnement a vie'
                          ) : (
                            <FormattedMessage
                              id='PLAN.DESCRIPTION.FULL'
                              values={{
                                type: (
                                  <FormattedMessage id={`PRICING.PAYMENT_DETAIL.${periodType}`} />
                                ),
                                name: (
                                  <FormattedMessage
                                    id={`PLANS.${plan?.name}`}
                                    defaultMessage={plan?.name}
                                  />
                                ),
                                price: (
                                  <strong>
                                    <CurrencyFormatter
                                      amount={
                                        (periodType === 'MONTHLY'
                                          ? plan?.price_per_month
                                          : plan?.price_per_year) || 0
                                      }
                                      currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                                      minimumFractionDigits={0}
                                    />
                                  </strong>
                                ),
                                priceVariation: <FormattedMessage id='COMMON.TAX.EXCL-TAXES' />,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              ))}
              {error && (
                <div className='text-danger mt-3'>
                  <FormattedMessage id={error.message} />
                </div>
              )}
            </>
          )}
        />
      </div>
    </Loader>
  );
}

export default UpgradeModalPlansSelection;
