import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FieldValues, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import { getLinkPayment } from '../../../setup/redux/effects/UtilsEffects';
import { RootState } from '../../../setup/Store';
import { ProfileExtraModule } from '../../models/ProfileExtras';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../drawer/drawer';
import Lottie from '../Lottie';
import {
  UpgradeModalStep1,
  UpgradeModalStep2,
  UpgradeModalStep3,
  UpgradeModalStep4,
  UpgradeStepper,
  upgradeSteps,
} from './';
import BankTransferPaymentProvider, {
  useBankTransferContext,
} from './providers/bankTransferPaymentProvider';
import paymentLoadingAnimation from '../../assets/payment_loading.json';
import paymentSuccessAnimation from '../../assets/paymentSucess.json';
import { delayPromise } from '../../utils/utils';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import Guard, { authorizeOnPlan } from '../Security/Guard';
import { UserPlan } from '../../modules/auth/models/UserModel';
import { useNavigate } from 'react-router-dom';

const freePlans = ['FREE', 'FREE14', 'FREE2', 'PROLONGATION STARTER', 'SWIVER_F6'] as UserPlan[];
type UpgradeModalProps = {
  children?: ReactNode | ((handleShowModal: () => void) => ReactNode);
};

const ModalContent = ({
  currentStep,
  isModalShown = false,
}: {
  currentStep: number;
  isModalShown?: boolean;
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const {
    methods: {
      formState: { isSubmitting: isBankTransferSubmitting },
    },
    isPaymentSuccessful,
  } = useBankTransferContext();

  if (isSubmitting || isBankTransferSubmitting)
    return (
      <div className='d-flex justify-content-center'>
        <Lottie animation={paymentLoadingAnimation} height={300} width={300}></Lottie>
      </div>
    );

  if (currentStep === 3 && isPaymentSuccessful) {
    return (
      <div className='d-flex justify-content-center'>
        <Lottie animation={paymentSuccessAnimation} height={300} width={300}></Lottie>
      </div>
    );
  }
  return (
    <>
      {currentStep <= upgradeSteps.length - 1 && <UpgradeStepper currentStep={currentStep} />}
      {currentStep === 0 ? (
        <UpgradeModalStep1 isModalShown={isModalShown} />
      ) : currentStep === 1 ? (
        <UpgradeModalStep2 isModalShown={isModalShown} />
      ) : currentStep === 2 ? (
        <UpgradeModalStep3 isModalShown={isModalShown} />
      ) : currentStep === 3 ? (
        <UpgradeModalStep4 isModalShown={isModalShown} />
      ) : (
        'Step not implemented'
      )}
    </>
  );
};

function UpgradeModal({ children }: UpgradeModalProps) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const currentPlan = useSelector((state: RootState) => state.auth.user?.current_plan);
  const currentCompany = useSelector((state: RootState) => state.auth.user?.current_company);
  const currentUser = useSelector((state: RootState) => state.auth.user?.id);
  const { formatMessage } = useIntl();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    defaultValues: {
      plan: currentPlan,
      extra_modules: (currentCompany?.module_subscriptions || [])
        .filter((subscription) => !subscription.is_free)
        .map((subscription) => subscription.extras_module)
        .reduce((prev, current) => {
          return prev.some((item) => item.id === current.id) ? prev : [...prev, current];
        }, [] as ProfileExtraModule[]),
      companies: 1,
      paymentType: 2,
      paymentGateway: '',
      period: 12,
      periodType: 'YEARLY',
      coupon: '',
      address: '',
    },
  });
  const {
    resetForm,
    setPaymentAmount,
    paymentId,
    setPaymentId,
    isPaymentSuccessful,
    setIsPaymentSuccessful,
    methods: bankTransferMethods,
  } = useBankTransferContext();
  const queryClient = useQueryClient();

  const handleShowModal = () => {
    setIsModalShown(true);
    methods.reset({
      plan: currentPlan,
      extra_modules: (currentCompany?.module_subscriptions || [])
        .filter((subscription) => !subscription.is_free)
        .map((subscription) => subscription.extras_module),
      paymentType: 2,
      paymentGateway: '',
      period: 12,
      periodType: 'YEARLY',
      coupon: '',
      address: currentCompany?.setting?.address
        ? `${currentCompany?.setting?.address || ''}, ${currentCompany?.setting?.region || ''}, ${
            currentCompany?.setting?.country || ''
          }, ${currentCompany?.setting?.zip_code || ''}`
        : '',
    });
    resetForm();
    setPaymentAmount(undefined);
    setPaymentId(undefined);
    setIsPaymentSuccessful(false);
  };
  const handleHideModal = () => {
    setIsModalShown(false);
    setCurrentStep(0);
  };
  const isMd = useMediaQuery('(min-width: 768px)');
  const content = useMemo(
    () => <ModalContent currentStep={currentStep} isModalShown={isModalShown} />,
    [currentStep, isModalShown]
  );
  const navigate = useNavigate();
  const onSubmitStep = async (values: FieldValues) => {
    if (currentStep < upgradeSteps.length - 1) {
      setCurrentStep((step) => Math.min(step + 1, upgradeSteps.length));
    } else {
      const selectedPlan = methods.getValues('plan');
      const baseCompanyCount =
        +(selectedPlan?.extra_company || 0) + +(selectedPlan?.max_company || 0);
      const baseUserCount = +(selectedPlan?.extra_user || 0) + +(selectedPlan?.max_user || 0);
      try {
        const { data } = await delayPromise(
          getLinkPayment({
            paymentType: values.paymentType,
            paymentGateway: values.paymentGateway,
            extrasModules: (values?.extra_modules || []).map(
              (extra: ProfileExtraModule) => extra.id
            ),
            type: 1,
            address: currentCompany?.setting?.address || '',
            period: 12,
            periodType: 'YEARLY',
            coupon: '',
            packSms: 0,
            extraCompany: Math.max(
              values.plan?.extra_company || 0,
              currentPlan?.extra_company || 0,
              0
            ),
            extraUser: Math.max(values.plan?.extra_user || 0, 0),
            bankTransferNumber: '',
            user: currentUser,
            plan: values?.plan?.id,
            currency: process.env.REACT_APP_DEFAULT_CURRENCY,
            taxNumber: values.taxNumber,
          }),
          1500
        );
        queryClient.invalidateQueries(['subscriptions']);
        if (+values.paymentType === 2) {
          setCurrentStep((step) => step + 1);
          setPaymentAmount({
            exclTaxes: data?.amount_ht || 0,
            inclTaxes: data?.amount_ttc || 0,
            currency: data?.currency || process.env.REACT_APP_DEFAULT_CURRENCY || 'TND',
          });
          setPaymentId(data?.id);
        } else {
          window.location.href = data.message;
        }
      } catch (error) {
        toast.error(formatMessage({ id: 'TOAST.ERROR' }));
      }
    }
  };

  const onPaymentTranferSubmit = async (values: FieldValues) => {
    const formData = new FormData();
    formData.append('bankTransferNumber', values.reference || '');
    formData.append('file', values.file || '');
    try {
      await delayPromise(
        axios.post(`/secure/user/purchases/edit/${paymentId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
        1500
      );
      setIsPaymentSuccessful(true);
      queryClient.invalidateQueries(['subscriptions']);
      navigate('/profile/susbcriptions');
      setTimeout(() => {
        handleHideModal();
      }, 1500);
    } catch {
      setIsPaymentSuccessful(false);
    }
  };

  const previousStep = () => {
    if (currentStep === 0 || currentStep === 3) {
      if (currentStep === 3) {
        navigate('/profile/susbcriptions');
      }
      handleHideModal();
    } else {
      setCurrentStep((step) => Math.max(step - 1, 0));
    }
  };

  return (
    <FormProvider {...methods}>
      {isMd ? (
        <>
          {typeof children === 'function' ? children(handleShowModal) : children}
          <Modal
            size='lg'
            className='upgrade-modal'
            show={isModalShown}
            onHide={handleHideModal}
            backdrop={currentStep === 0 ? undefined : 'static'}
            centered>
            <form
              onSubmit={
                currentStep === 3
                  ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                  : methods.handleSubmit(onSubmitStep)
              }>
              <div className='upgrade-modal-header'>
                {/* <div> */}
                <span className='upgrade-modal-title'>
                  <Guard
                    plans={freePlans}
                    authorize={authorizeOnPlan}
                    alt={<FormattedMessage id='EXPIRATION.HEADER.RENEW' />}>
                    <FormattedMessage id='USER.MENU.ACCOUNT.UPGRADE' />
                  </Guard>
                </span>
                {/* </div> */}
                <div className='upgrade-modal-toolbar'>
                  <button
                    type='button'
                    className='btn btn-light'
                    disabled={
                      methods.formState.isSubmitting || bankTransferMethods.formState.isSubmitting
                    }
                    onClick={previousStep}>
                    <FormattedMessage
                      id={
                        currentStep === 0 || currentStep === 3
                          ? 'FORM.CLOSE'
                          : 'DOCUMENT.ACTIONS.RETURN'
                      }
                    />
                  </button>
                  {(currentStep !== 3 || !isPaymentSuccessful) && (
                    <button
                      className='btn btn-primary'
                      disabled={
                        methods.formState.isSubmitting || bankTransferMethods.formState.isSubmitting
                      }
                      onClick={() => {
                        currentStep === 3
                          ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                          : methods.handleSubmit(onSubmitStep);
                      }}>
                      <FormattedMessage id={currentStep > 2 ? 'FORM.CONTINUE' : 'FORM.SUBMIT'} />
                    </button>
                  )}
                </div>
              </div>
              <div className='upgrade-modal-body d-flex flex-column gap-6'>{content}</div>
            </form>
          </Modal>
        </>
      ) : (
        <Drawer open={isModalShown} onClose={handleHideModal}>
          <DrawerTrigger asChild>
            {typeof children === 'function'
              ? children(() => {
                  handleShowModal();
                })
              : children}
          </DrawerTrigger>

          <DrawerContent>
            <form
              onSubmit={
                currentStep === 3
                  ? bankTransferMethods.handleSubmit(onPaymentTranferSubmit)
                  : methods.handleSubmit(onSubmitStep)
              }>
              <DrawerHeader className='d-flex justify-content-between align-items-center'>
                <div className='w-60px'>
                  <button
                    type='button'
                    className='btn btn-icon btn-sm vaul-drawer-navigation'
                    disabled={
                      methods.formState.isSubmitting || bankTransferMethods.formState.isSubmitting
                    }
                    onClick={previousStep}>
                    <i className='fa-light fa-arrow-left' />
                  </button>
                </div>
                <DrawerTitle>
                  <Guard
                    plans={freePlans}
                    authorize={authorizeOnPlan}
                    alt={<FormattedMessage id='EXPIRATION.HEADER.RENEW' />}>
                    <FormattedMessage id='USER.MENU.ACCOUNT.UPGRADE' />
                  </Guard>
                </DrawerTitle>
                <div className='w-60px'></div>
              </DrawerHeader>
              <div className='upgrade-modal-body d-flex flex-column gap-6'>{content}</div>
              <DrawerFooter>
                <button
                  className='btn btn-primary w-100'
                  disabled={
                    methods.formState.isSubmitting || bankTransferMethods.formState.isSubmitting
                  }>
                  <FormattedMessage id={currentStep > 2 ? 'FORM.CONTINUE' : 'FORM.SUBMIT'} />
                </button>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </Drawer>
      )}
    </FormProvider>
  );
}
const UpgradeModalContainer = ({ children }: UpgradeModalProps) => {
  return (
    <BankTransferPaymentProvider>
      <UpgradeModal>{children}</UpgradeModal>
    </BankTransferPaymentProvider>
  );
};

export { UpgradeModalContainer as UpgradeModal };
