import {
  OnChangeFn,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useVirtual } from 'react-virtual';
import Loader from '../Loader/Loader';

export interface SwTableProps {
  data: any[];
  columns: any[];
  isLoading?: boolean;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState> | undefined;
  enableSorting?: boolean;
}

const SwTable: React.FC<SwTableProps> = ({
  data = [],
  columns,
  isLoading = false,
  sorting = [],
  onSortingChange = undefined,
  enableSorting = false,
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: onSortingChange,
    debugTable: process.env.NODE_ENV !== 'production',
    enableSorting,
  });
  const { rows } = table.getRowModel();
  const { virtualItems: virtualRows } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it

  return (
    <div className='sw-table-container' ref={tableContainerRef}>
      <table className='table sw-table '>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width: header.getSize(),
                    },
                  }}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}>
                      {header.column.getCanSort() ? (
                        <div className='d-flex gap-3 align-items-center'>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* <i class="fa-light fa-caret-up"></i> */}
                          {!!header.column.getIsSorted() && (
                            <i
                              className={clsx(
                                'fa-light text-gray-600 fs-4',
                                header.column.getIsSorted() === 'desc'
                                  ? 'fa-caret-down'
                                  : 'fa-caret-up'
                              )}></i>
                          )}
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {virtualRows.length === 0 && !isLoading && (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <div className='d-flex justify-content-center align-items-center'>
                  <div className='fs-3 text-center align-middle text-gray-700 py-20'>
                    <span>
                      <FormattedMessage id='TABLE.EMPTY' />
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <div className='d-flex justify-content-center align-items-center'>
                  <div className='fs-3 text-center align-middle text-gray-700 py-20'>
                    <Loader loading={isLoading}></Loader>
                  </div>
                </div>
              </td>
            </tr>
          )}
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SwTable;
