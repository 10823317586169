import axios from 'axios';
import {
  Amounts,
  DeliveryNoteAmounts,
  DocumentQuery,
  ListDocumentsResponse,
} from '../../../app/models/ListDocumentModels';
import { delayPromise } from '../../../app/utils/utils';

export type payloadConvert = {
  id: number;
  from: string;
  to: string;
  ids?: number[];
  allLines?: boolean;
};

export function getDocuments(params: Partial<DocumentQuery>) {
  return delayPromise(
    axios.get<ListDocumentsResponse>('/secure/documents/v2', {
      params: {
        ...params,
        states: params?.states?.includes('all') ? '' : params.states?.join(','),
        converted: params.converted ? params.converted : '',
        is_not_converted: params.is_not_converted ? !params.is_not_converted : '',
        is_credit_note: params.is_credit_note || '',
        partial_paid: params.partial_paid || '',
        late: params.late || '',
        generate_with_pos: params.generate_with_pos === false ? '' : true,
      },
    })
  );
}

export function getDocumentsStats(params: Partial<DocumentQuery>) {
  return delayPromise(
    axios.get<{ amounts: Amounts | DeliveryNoteAmounts }>('/secure/documents/stats', {
      params: {
        ...params,
        states: params?.states?.includes('all') ? '' : params.states?.join(','),
        converted: params.converted ? params.converted : '',
        is_not_converted: params.is_not_converted ? !params.is_not_converted : '',
        is_credit_note: params.is_credit_note || '',
        partial_paid: params.partial_paid || '',
        late: params.late || '',
        generate_with_pos: params.generate_with_pos === false ? '' : true,
      },
    })
  );
}

export function convertDocument(params: Partial<payloadConvert>) {
  return delayPromise(
    axios.post<any>(`/secure/${params.from}/convert/${params.to}`, {
      ids: [params?.id],
      allLines: true,
    })
  );
}

export function convertMultiDocument(params: Partial<payloadConvert>) {
  return delayPromise(
    axios.post<any>(`/secure/${params.from}/convert/${params.to}`, {
      ids: params?.ids,
      allLines: params?.allLines,
    })
  );
}
