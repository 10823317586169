import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'usehooks-ts';
import { RootState } from '../../../setup/Store';
import { UserPlan } from '../../modules/auth/models/UserModel';
import Guard, { authorizeOnPlan, authorizeOnRole } from '../Security/Guard';
import UpgradeModal from '../UpgradeModal';
const freePlans = ['FREE', 'FREE14', 'FREE2', 'PROLONGATION STARTER', 'SWIVER_F6'] as UserPlan[];
function UpgradeAlert() {
  const isMd = useMediaQuery('(min-width: 768px)');
  const user = useSelector((state: RootState) => state.auth.user);
  const daysDifference = useMemo(
    () => differenceInDays(new Date(user?.subscription_expiry as string), new Date()),
    [user?.subscription_expiry]
  );
  const { formatRelativeTime } = useIntl();

  return (
    <Guard
      plans={freePlans}
      authorize={(inputs) => daysDifference <= 14 || authorizeOnPlan(inputs)}>
      <UpgradeModal>
        {(handleShowModal) => (
          <>
            <div
              className={clsx('sw-upgrade-alert', {
                'sw-upgrade-alert-primary': daysDifference > 14,
                'sw-upgrade-alert-warning': daysDifference <= 14 && daysDifference > 7,
                'sw-upgrade-alert-danger': daysDifference <= 7,
              })}>
              {isMd ? (
                <>
                  <i className='fa-sharp fa-thin fa-gem'></i>
                  <Guard
                    plans={freePlans}
                    authorize={authorizeOnPlan}
                    alt={
                      <FormattedMessage
                        id='EXPIRATION.HEADER.TITLE'
                        values={{ relativetimeformat: formatRelativeTime(daysDifference, 'days') }}
                      />
                    }>
                    <FormattedMessage id='UPGRADE_ALERT.UNLOCK_MORE_FEATURES' />
                  </Guard>
                  <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
                    <div onClick={handleShowModal} className='btn'>
                      <Guard
                        plans={freePlans}
                        authorize={authorizeOnPlan}
                        alt={<FormattedMessage id='EXPIRATION.HEADER.RENEW' />}>
                        <FormattedMessage id='USER.MENU.ACCOUNT.UPGRADE' />
                      </Guard>
                    </div>
                  </Guard>
                </>
              ) : (
                <Guard
                  roles={['ROLE_ADMIN']}
                  authorize={authorizeOnRole}
                  alt={
                    <div className='btn btn-icon'>
                      <i className='fa-sharp fa-thin fa-gem'></i>
                    </div>
                  }>
                  <div onClick={handleShowModal} className='btn btn-icon'>
                    <i className='fa-sharp fa-thin fa-gem'></i>
                  </div>
                </Guard>
              )}
            </div>
          </>
        )}
      </UpgradeModal>
    </Guard>
  );
}

export { UpgradeAlert };
