import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../setup/Store';

function UpgradeModalStep2({ isModalShown = false }) {
  // const { data: paymentGateways } = usePaymentGateways({ shouldFetch: isModalShown });
  // const paymentGateway = useWatch({ name: 'paymentGateway' });

  const { setValue } = useFormContext();
  const companyName = useSelector((state: RootState) => state.auth.user?.current_company.name);
  const userPhone = useSelector((state: RootState) => state.auth.user?.phone);
  const companyTax = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting.tax_number
  );
  // const subscriptionExpiry = useSelector(
  //   (state: RootState) => state.auth.user?.subscription_expiry
  // );
  useEffect(() => {
    if (isModalShown) setValue('taxNumber', companyTax);
  }, [isModalShown]);

  return (
    <div className='upgrade-modal-details'>
      <div className='row g-2'>
        <div className='col-md-7'>
          <div className='upgrade-modal-form-label-container h-100 align-middle'>
            <i className='fa-light fa-user'></i>
            <span className='upgrade-modal-form-label'>
              <FormattedMessage id='FORM.FIELD.COMPANY' />
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <input
            type='text'
            value={companyName}
            disabled
            className='form-control form-control-solid bg-secondary'
          />
        </div>
        <div className='col-md-7'>
          <div className='upgrade-modal-form-label-container h-100 align-middle'>
            <i className='fa-light fa-envelope'></i>
            <span className='upgrade-modal-form-label'>
              <FormattedMessage id='TAX.NUMBER' />
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <Controller
            name='taxNumber'
            disabled={!!companyTax}
            rules={{ required: 'FORM.ERRORS.REQUIRED' }}
            render={({ field, fieldState }) => (
              <div className='d-flex flex-column gap-2'>
                <input
                  type='text'
                  {...field}
                  className='form-control form-control-solid bg-secondary'
                />
                {fieldState.error && (
                  <div className='text-danger'>
                    <FormattedMessage
                      id={fieldState.error.message}
                      values={{ field: 'Tax number' }}
                    />
                  </div>
                )}
              </div>
            )}
          />
        </div>
        <div className='col-md-7'>
          <div className='upgrade-modal-form-label-container h-100 align-middle'>
            <i className='fa-light fa-mobile'></i>
            <span className='upgrade-modal-form-label'>
              <FormattedMessage id='CUSTOMER.FIELD.PHONE' />
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <input
            type='text'
            value={userPhone}
            disabled
            dir='ltr'
            className='form-control form-control-solid bg-secondary'
          />
        </div>
        <div className='col-md-7'>
          <div className='upgrade-modal-form-label-container h-100 align-middle'>
            <i className='fa-light fa-building'></i>
            <span className='upgrade-modal-form-label'>
              <FormattedMessage id='FORM.FIELD.ADDRESS' />
            </span>
          </div>
        </div>
        <div className='col-md-5'>
          <Controller
            name='address'
            rules={{ required: 'FORM.ERRORS.REQUIRED' }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <>
                <input
                  type='text'
                  {...field}
                  className='form-control form-control-solid bg-secondary'
                />
                {error && isTouched && (
                  <div className='text-danger'>
                    <FormattedMessage id={error?.message} values={{ field: 'Adresse' }} />
                  </div>
                )}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default UpgradeModalStep2;
