import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useProfileExtrasQuery from '../../../hooks/queryHooks/useProfileExtrasQuery';
import { useUserPricesQuery } from '../../../hooks/queryHooks/useUserPricesQuery';
import { ProfileExtraModule } from '../../../models/ProfileExtras';
import { getArrayFromObject } from '../../../utils/utils';
import StyledCurrency from '../../StyledCurrency/StyledCurrency';

function UpgradeModalDetailedInvoice({ isModalShown = false, isFromUpgradeModal = true }) {
  const plan = useWatch({ name: 'plan' });
  useWatch({ name: 'periodType' });
  const { data: subscriptionPrices } = useUserPricesQuery({
    shouldFetch: isModalShown,
    priceType: 'YEARLY',
  });
  const minCompanies = useMemo(
    () => (!plan ? 0 : +(plan?.extra_company || 0) + +(plan?.max_company || 0)),
    [plan]
  );
  const period = useWatch({ name: 'period', defaultValue: 12 });

  const minUsers = useMemo(
    () => (!plan ? 0 : +(plan?.extra_user || 0) + +(plan?.max_user || 0)),
    [plan]
  );
  const extraCompany = useWatch({ name: 'extra_company' });
  const extraUser = useWatch({ name: 'extra_user' });

  const companiesCount = useWatch({ name: 'companies', exact: true });
  const extraModules: ProfileExtraModule[] = useWatch({ name: 'extra_modules', defaultValue: [] });
  const companyPrice = useMemo(() => {
    if (!subscriptionPrices) return 0;
    return subscriptionPrices.find((price) => price.code === 'EXTRA_COMPANY')?.amount_ht || 0;
  }, [subscriptionPrices]);
  const { data: extrasData } = useProfileExtrasQuery(isModalShown);
  const extrasList = useMemo(() => {
    const extras = getArrayFromObject(extraModules || []).map(
      (extraModule) =>
        (extrasData || [])?.find((extra) => extra.id === extraModule?.id) || extraModule
    );
    return extras;
  }, [extrasData, extraModules]);
  const extraCompanies = useMemo(
    () => (!plan ? 0 : Math.max(0, (companiesCount || 1) - minCompanies)),
    [minCompanies, companiesCount]
  );

  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead>
          <tr>
            <th className='upgrade-modal-checkout-title p-0 pb-1'>
              <FormattedMessage id='COMMON.FIELDS.DESIGNATION' />
            </th>
            <th className='upgrade-modal-checkout-title p-0 pb-1'>
              <FormattedMessage id='COMMON.FIELDS.QTE' />
            </th>
            <th className='upgrade-modal-checkout-title p-0 pb-1 text-end'>
              <FormattedMessage id='DOCUMENT.FIELDS.EXCL-TAXES-PRICE' />
            </th>
          </tr>
        </thead>
        <tbody>
          {!!plan && (
            <tr>
              <td className='p-0 py-1'>
                <div className='d-flex flex-column'>
                  <div className='upgrade-modal-checkout-value'>
                    <FormattedMessage
                      id='PLAN.DESCRIPTION'
                      values={{
                        type: <FormattedMessage id='PRICING.PAYMENT_DETAIL.YEARLY' />,
                        name: (
                          <FormattedMessage id={`PLANS.${plan.name}`} defaultMessage={plan.name} />
                        ),
                      }}
                    />
                  </div>
                  <StyledCurrency
                    amount={(plan.price_per_year || 0) / period}
                    currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                    amountClassName={'upgrade-modal-checkout-amount'}
                    supClassName={'upgrade-modal-checkout-amount-currency'}
                    containerClassName={'d-flex gap-1 mt-1'}
                  />
                  <div className='upgrade-modal-checkout-description'>
                    *{' '}
                    <FormattedMessage id='UPGRADE_MODAL.MONTHS_COUNT' values={{ count: period }} />
                  </div>
                </div>
              </td>
              <td className='p-0 py-1'>
                <FormattedMessage id='UPGRADE_MODAL.MONTHS_COUNT' values={{ count: period }} />
              </td>
              <td className='p-0 py-1'>
                <StyledCurrency
                  amountClassName='upgrade-modal-amount'
                  supClassName='upgrade-modal-amount-currency'
                  containerClassName='d-flex align-items-center gap-1 justify-content-end'
                  amount={plan?.price_per_year || 0}
                  currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                />
              </td>
            </tr>
          )}
          {!!(extraCompanies > 0 || (!isFromUpgradeModal && extraCompany)) && (
            <tr>
              <td className='p-0'>
                <div className='d-flex flex-column'>
                  <div className='upgrade-modal-checkout-value'>
                    <FormattedMessage id='PROFILE.PLAN.EXTRA_COMPANY' />
                  </div>
                  <StyledCurrency
                    amount={companyPrice}
                    currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                    amountClassName={'upgrade-modal-checkout-amount'}
                    supClassName={'upgrade-modal-checkout-amount-currency'}
                    containerClassName={'d-flex gap-1 mt-1'}
                  />
                  <div className='upgrade-modal-checkout-description'>
                    *{' '}
                    <FormattedMessage id='UPGRADE_MODAL.MONTHS_COUNT' values={{ count: period }} />
                  </div>
                </div>
              </td>
              <td className='p-0'>
                {!isFromUpgradeModal ? extraCompany : extraCompanies} *{' '}
                <FormattedMessage id='UPGRADE_MODAL.MONTHS_COUNT' values={{ count: period }} />
              </td>
              <td className='p-0'>
                <StyledCurrency
                  amountClassName='upgrade-modal-amount'
                  supClassName='upgrade-modal-amount-currency'
                  containerClassName='d-flex align-items-center gap-1 justify-content-end'
                  amount={
                    companyPrice * (!isFromUpgradeModal ? extraCompany : extraCompanies) * period
                  }
                  currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                />
              </td>
            </tr>
          )}
          {extrasList.map((extraModule) => (
            <tr>
              <td className='p-0 py-1'>
                <div className='d-flex flex-column'>
                  <div className='upgrade-modal-checkout-value'>
                    <FormattedMessage id='PROFILE.EXTRA' />: {extraModule.name}
                  </div>
                  <StyledCurrency
                    amount={+(extraModule.price_ht || 0)}
                    currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                    amountClassName={'upgrade-modal-checkout-amount'}
                    supClassName={'upgrade-modal-checkout-amount-currency'}
                    containerClassName={'d-flex gap-1 mt-1'}
                  />
                </div>
              </td>
              <td className='p-0 py-1'>
                <FormattedMessage id='UPGRADE_MODAL.MONTHS_COUNT' values={{ count: period }} />
              </td>
              <td className='p-0 py-1'>
                <StyledCurrency
                  amountClassName='upgrade-modal-amount'
                  supClassName='upgrade-modal-amount-currency'
                  containerClassName='d-flex align-items-center gap-1 justify-content-end'
                  amount={+(extraModule.price_ht || 0) * period || 0}
                  currency={process.env.REACT_APP_DEFAULT_CURRENCY}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UpgradeModalDetailedInvoice;
