import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Editor from 'ckeditor5-custom-build';
import dompurify from 'dompurify';
import produce from 'immer';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Control, Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setPageData } from '../../../../setup/redux/reducers/PageReducer';
import { RootState } from '../../../../setup/Store';
import Loader from '../../../components/Loader/Loader';
import {
  TICKETS_DETAILS_QUERY_KEY,
  TICKETS_LIST_QUERY_KEY,
  useTicket,
} from '../../../hooks/queryHooks/useTickets';
import { Attachment } from '../../../models/ticketDetailReponse';
import Image from '../../../components/Image/Image';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
const defaultTicketValues = {
  response: '',
  attachements: [] as File[],
};
function DetailsTicket() {
  const { id } = useParams<{ id: string }>();
  const { data, isInitialLoading, isLoading } = useTicket(Number(id));
  const dispatch = useDispatch();
  const locale = useSelector((state: RootState) => state.utils.locale);
  const queryClient = useQueryClient();
  const sanitizedData = useMemo(() => {
    const parser = new DOMParser();
    return data
      ? produce(data, (draft) => {
          draft.ticket.threads = draft.ticket.threads
            .filter((thread) => thread.threadType !== 'note')
            .map((thread) => {
              const decodedMessage = parser.parseFromString(thread.message, 'text/html');
              const message = dompurify.sanitize(decodedMessage.documentElement.textContent || '');
              return { ...thread, message };
            });
        })
      : undefined;
  }, [data]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: defaultTicketValues,
  });
  useEffect(() => {
    dispatch(
      setPageData({
        title: <FormattedMessage id='Tickets.TicketDetails' defaultMessage={'Détails du ticket'} />,
        actionBar: 'customActions',
        customActions: (
          <Link to='/tickets' className='btn btn-primary btn-sm ms-auto'>
            <FormattedMessage id='BUTTON.RETURN.TO.LIST' />
          </Link>
        ),
      })
    );
  }, [dispatch]);
  const onSubmit = async (data: { response: string; attachements: File[] }) => {
    const formData = new FormData();
    formData.append('message', data.response);
    data.attachements.forEach((file, index) => {
      formData.append(`attachments[${index + 1}]`, file);
    });

    await axios.post(`/secure/tickets/ticket/${sanitizedData?.ticket.id}/thread`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    reset(defaultTicketValues, { keepValues: false, keepErrors: false, keepTouched: false });
    queryClient.invalidateQueries([...TICKETS_DETAILS_QUERY_KEY, Number(id)]);
    queryClient.invalidateQueries(TICKETS_LIST_QUERY_KEY);
  };
  const companyLogo = useSelector(
    (state: RootState) => state.auth.user?.current_company.setting.logo?.thumb75
  );
  return (
    <Loader loading={isInitialLoading && isLoading}>
      <div className='row g-4'>
        {/* <div></div> */}
        <div className='col-md-4'>
          <div className='card card-flush'>
            <div className='card-header'>
              <div className='card-title'>
                {/* Ticket &nbsp; <b>#{data?.ticket.id}</b>: {data?.ticket?.subject} */}
                <FormattedMessage
                  id='TICKETS.TICKET_TITLE'
                  values={{
                    ticketNumber: (
                      <>
                        &nbsp;<b>#{data?.ticket.id}</b>&nbsp;
                      </>
                    ),
                    subject: data?.ticket?.subject || '',
                  }}
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-column gap-4'>
                <div className='d-flex gap-2'>
                  <div className='symbol symbol-40px'>
                    <img src={data?.ticket.agent.thumbnail} alt='' />
                  </div>
                  <div className='d-flex flex-column justify-content-between gap-1'>
                    <span className='fw-semibold fs-9'>
                      <FormattedMessage id='TICKETS.AGENT' />
                    </span>
                    <span className='fw-bold fs-7'>{data?.ticket.agent.name}</span>
                  </div>
                </div>
                <div className='d-flex gap-2'>
                  <div className='symbol symbol-40px '>
                    <div className='symbol-label fs-2 bg-light-primary'>
                      <i className='fa-light fa-timer text-primary'></i>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-between gap-1'>
                    <span className='fw-semibold fs-9'>
                      <FormattedMessage id='TICKETS.CREATED_AT' />
                    </span>
                    <span className='fw-bold fs-7'>{data?.ticket.createdAt}</span>
                  </div>
                </div>
                <div className='d-flex gap-2'>
                  <div className='symbol symbol-40px'>
                    <div className='symbol-label fs-2 bg-light-info'>
                      <i className='fa-light fa-info text-info'></i>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-between gap-1'>
                    <span className='fw-semibold fs-9'>
                      <FormattedMessage id='TICKETS.STATUS.TITLE' />
                    </span>
                    <span
                      className='fw-bold fs-7'
                      style={{
                        color: data?.ticketStatuses.find((s) => s.id === data?.ticket.status)
                          ?.colorCode,
                      }}>
                      <FormattedMessage
                        id={`TICKETS.STATUS.${(
                          data?.ticketStatuses.find((s) => s.id === data?.ticket.status)?.code || ''
                        ).toUpperCase()}`}
                        defaultMessage={
                          data?.ticketStatuses.find((s) => s.id === data?.ticket.status)?.code || ''
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className='d-flex gap-2'>
                  <div className='symbol symbol-40px'>
                    <div className='symbol-label fs-2 bg-light-danger'>
                      <i className='fa-light fa-exclamation text-danger'></i>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-between gap-1'>
                    <span className='fw-semibold fs-9'>
                      <FormattedMessage id='TICKETS.PRIORITY.TITLE' />
                    </span>
                    <span
                      className='fw-bold fs-7'
                      style={{
                        color: data?.ticketPriorities.find((s) => s.id === data?.ticket.priority)
                          ?.colorCode,
                      }}>
                      <FormattedMessage
                        id={`TICKETS.PRIORITY.${(
                          data?.ticketPriorities.find((s) => s.id === data?.ticket.priority)
                            ?.code || ''
                        ).toUpperCase()}`}
                        defaultMessage={
                          data?.ticketPriorities.find((s) => s.id === data?.ticket.priority)
                            ?.code || ''
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          <div className='d-flex flex-column gap-4'>
            {sanitizedData?.ticket.threads.map((thread) => (
              <div className='card card-flush position-relative' key={thread.id}>
                <div className='position-absolute start-0 end-0 w-4px h-100 bg-primary rounded-start'></div>
                <div className='card-header min-h-auto px-6 py-2'>
                  <div className='d-flex align-items-center gap-3 w-100'>
                    <div className='symbol symbol-20px'>
                      {thread?.createdBy === 'customer' ? (
                        <Image
                          src={companyLogo || ''}
                          fallback={
                            <KTSVG
                              path={toAbsoluteUrl('/media/icons/duotune/communication/com013.svg')}
                              className='svg-icon-1 svg-icon-gray-600'
                            />
                          }
                          alt="User's avatar"
                        />
                      ) : (
                        <Image
                          src={thread.user.thumbnail}
                          alt='Support agent'
                          fallback={
                            <KTSVG
                              path={toAbsoluteUrl('/media/icons/swiver/support.svg')}
                              className='svg-icon-1 svg-icon-primary'
                            />
                          }
                        />
                      )}
                    </div>
                    <span className='fs-6 fw-semibold'>{thread.user.name}</span>
                    <span className='ms-auto text-gray-600'>
                      {thread.updatedAt || thread.createdAt}
                    </span>
                  </div>
                </div>
                <div className='card-body px-6 py-2'>
                  <div dangerouslySetInnerHTML={{ __html: thread.message }}></div>
                </div>
                {thread.attachments.length > 0 && (
                  <div className='card-footer d-flex flex-column gap-2'>
                    <span>
                      <FormattedMessage id='TICKETS.ATTACHEMENTS.LIST' />:
                    </span>
                    <Attachments attachments={thread.attachments} />
                  </div>
                )}
              </div>
            ))}
            <form className='card card-flush' onSubmit={handleSubmit(onSubmit)}>
              <div className='card-header px-6 py-2 min-h-auto'>
                <div className='card-title'>
                  <FormattedMessage id='TICKETS.RESPONSE' />
                </div>
              </div>
              <div className='card-body px-6 py-2'>
                <div className='bg-light'>
                  <Controller
                    name='response'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CKEditor
                        editor={Editor}
                        data={value || ''}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChange(data);
                        }}
                        onBlur={onBlur}
                        disabled={isSubmitting}
                        config={{
                          language: locale,
                          initialData: '',
                        }}
                      />
                    )}
                  />
                </div>
                <ReplyAttachements control={control} />
              </div>
              <div className='card-footer px-6 py-2 d-flex justify-content-between'>
                <Controller
                  name='attachements'
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        id={`attachements`}
                        type='file'
                        name={field.name}
                        multiple
                        onChange={(e) => {
                          const files = Array.from(e.target.files || []);
                          field.onChange(files);
                        }}
                        disabled={isSubmitting}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        className='d-none'
                      />
                      <label
                        className='btn btn-light btn-active-light-primary'
                        htmlFor='attachements'>
                        <FormattedMessage id='TICKETS.ATTACHEMENTS.ADD' />
                      </label>
                    </>
                  )}
                />
                <button disabled={isSubmitting} type='submit' className='btn btn-primary'>
                  <FormattedMessage id='TICKETS.SEND' />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Loader>
  );
}

const ReplyAttachements = ({
  control,
}: {
  control: Control<
    {
      response: string;
      attachements: File[];
    },
    any
  >;
}) => {
  return (
    <Controller
      name='attachements'
      control={control}
      render={({ field }) => (
        <>
          {(field?.value as File[])?.length > 0 && (
            <ul className='list-group mt-4'>
              {(field.value as File[]).map((file, index) => (
                <li
                  key={index}
                  className='list-group-item d-flex justify-content-between align-items-center'>
                  {file.name}
                  <button
                    type='button'
                    className='btn btn-danger btn-sm'
                    onClick={() => {
                      field.onChange((field.value as File[]).filter((_, i) => i !== index));
                    }}>
                    <FormattedMessage id='FORM.DELETE' />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    />
  );
};

const Attachments = ({ attachments }: { attachments: Attachment[] }) => {
  const [selectedAttachement, setSelectedAttachement] = useState<Attachment>();
  return (
    <>
      <div className='d-flex align-items-center gap-4 flex-wrap'>
        {attachments.map((attachment) => (
          <div
            key={attachment.id}
            onClick={() => setSelectedAttachement(attachment)}
            className='symbol overlay symbol-75px'>
            {/* <div className='overlay-wrapper'> */}
            <img src={attachment.path} alt='' />
            {/* </div> */}
            <div className='overlay-layer bg-secondary bg-opacity-25'>
              <i className='fa-light fa-eye text-primary' />
            </div>
          </div>
        ))}
      </div>
      <Modal show={!!selectedAttachement} centered onHide={() => setSelectedAttachement(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Attachement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='w-100' src={selectedAttachement?.path} alt='' />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DetailsTicket;
