import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { updateCompany } from '../../../setup/redux/reducers/AuthReducer';
import { readCountries, readCurrencies } from '../../../setup/redux/reducers/UtilsReducers';
import { KTSVG, toAbsoluteUrl } from '../../helpers';
import { Company, User } from '../../modules/auth/models/UserModel';
import AddCompany from '../../pages/Settings/company/AddCompany';
import PhoneDisplayFlag from '../PhoneDisplayFlag/PhoneDisplayFlag';
import Guard, { authorizeOnRole } from '../Security/Guard';
import { Link } from 'react-router-dom';

function CompanySelection() {
  const companies: Company[] = useSelector((state: RootState) => state.auth?.user?.companies) ?? [];
  const currencies = useSelector((state: RootState) => state.utils.currencies);
  const countries = useSelector((state: RootState) => state.utils.countries);
  const direction = useSelector((state: RootState) => state.utils.direction);
  const currentCompany: Company =
    useSelector((state: RootState) => state.auth?.user?.current_company) ?? ({} as Company);
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user) as User;
  const dispatch = useDispatch();
  const handleAllCompaniesClose = () => {
    setShowAllCompanies(false);
  };
  const changeCompany = (id: number) => {
    dispatch(updateCompany(id));
  };
  const totalCompanies = useMemo(() => {
    return (user?.current_plan?.max_company || 0) + (user?.current_plan?.extra_company || 0);
  }, [user]);
  const companyCredits = useMemo(() => {
    return user?.credits?.find((credit) => credit.resource === 'EXTRA_COMPANY')?.qty || 0;
  }, [user]);

  useEffect(() => {
    if (!currencies.length) {
      dispatch(readCurrencies());
    }
    if (!countries) {
      dispatch(readCountries());
    }
  }, []);
  const [addCompany, setAddCompany] = useState(false);

  const [abortAddCompany, setAbortAddCompany] = useState(false);
  const handleCloseAdd = () => {
    setShowAllCompanies(false);
    setAbortAddCompany(false);
    setAddCompany(false);
  };
  return (
    <>
      <div className='app-header-company-selection'>
        <div className='company-badge cursor-pointer' onClick={() => setShowAllCompanies(true)}>
          <i className='fa-solid fa-grid companies'></i>
          <div className='company-label'>
            <FormattedMessage id='TOPBAR.MY_COMPANIES' />
          </div>
        </div>
        <i className='fa-solid fa-chevron-right company-separator'></i>
        <div className='company-badge'>
          <div className='company-logo'>
            <img
              src={
                currentCompany?.setting?.logo?.web_path || toAbsoluteUrl('/media/logos/logo.svg')
              }
              alt=''
            />
          </div>
          <div className='company-label'>{currentCompany?.name || '--'}</div>
        </div>
        <div className='company-badge mobile' onClick={() => setShowAllCompanies(true)}>
          <div className='company-logo'>
            <img
              src={
                currentCompany?.setting?.logo?.web_path || toAbsoluteUrl('/media/logos/logo.svg')
              }
              alt=''
            />
          </div>
          <i className='fa-light fa-chevron-down company-dropdown'></i>
        </div>
      </div>
      <Modal
        contentClassName='rounded-5'
        show={showAllCompanies}
        centered
        dialogClassName='rounded-5'
        size='xl'
        backdrop={addCompany ? 'static' : undefined}
        onHide={() => setShowAllCompanies(false)}>
        {!addCompany && (
          <Modal.Header className='border-bottom-0 p-0'>
            <div
              onClick={handleAllCompaniesClose}
              className='text-end ms-auto pt-2 pe-4 cursor-pointer '>
              <KTSVG
                className='svg-icon svg-icon-2qx svg-icon-gray'
                path='/media/icons/duotune/general/gen034.svg'
              />
            </div>
          </Modal.Header>
        )}

        <Modal.Body className='p-0 px-0'>
          {!addCompany && (
            <>
              <div className='d-flex flex-column gap-4 text-center w-100'>
                <p className='h1'>
                  <FormattedMessage id='COMMON.GREETING' /> {user?.first_name?.toUpperCase()}!
                </p>
                <p className='text-muted fw-semibold '>
                  <FormattedMessage id='CREATE.NEW.COMPANY.DESC1' />
                  <br />
                  <FormattedMessage id='CREATE.NEW.COMPANY.DESC2' />
                </p>
                <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
                  {(totalCompanies > companies?.length || !!companyCredits) && (
                    <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
                      {(totalCompanies > companies?.length || !!companyCredits) && (
                        <div className='text-center'>
                          <button
                            className='btn btn-primary btn-shadow btn-submit'
                            onClick={() => setAddCompany(true)}>
                            <i className='fa-light fa-circle-plus fs-1'></i>
                            <FormattedMessage id='CREATE.NEW.COMPANY.NEW.COMPANY' />
                          </button>
                        </div>
                      )}
                    </Guard>
                  )}
                </Guard>
              </div>
              <div className='row g-6 p-20 w-100'>
                <div className='col-md-6 col-lg-4'>
                  <div
                    className='sw-company rounded-5 border border-primary'
                    style={{ minHeight: '200px' }}>
                    <div className='d-flex flex-column justify-content-between h-100'>
                      <div className='sw-company-header'>
                        <div>
                          <img
                            className='logo-company p-1 bg-body  rounded-5'
                            src={
                              currentCompany?.setting?.logo?.web_path ||
                              toAbsoluteUrl('/media/avatars/swiver-avatar.png')
                            }
                            alt=''
                          />
                        </div>
                        <div className='header-company'>
                          <h3>{currentCompany?.name}</h3>
                          <div className='sw-company-matricule'>
                            <div className='w-20px'>
                              {!!currentCompany?.setting?.country && (
                                <PhoneDisplayFlag code={currentCompany?.setting?.country as any} />
                              )}
                            </div>
                            <span className='ms-2 fs-4 fw-semibold text-white'>
                              {currentCompany?.setting?.tax_number}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between bg-body'>
                        <div className='sw-company-footer'>
                          <h5 className='sw-info-profil'>
                            <FormattedMessage id='PROFILE.TITLE' />
                          </h5>
                          <h3 className='sw-info-role'>
                            {user?.first_name} {user?.last_name}
                          </h3>
                        </div>
                        <div className='sw-button-action'>
                          <button className='btn btn-shadow btn-primary btn-submit'>
                            <FormattedMessage id='COMMON.ACCESS' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {companies
                  ?.filter((company) => company?.id !== currentCompany?.id)
                  .map((company, index) => (
                    <div key={company.id || index} className='col-md-6 col-lg-4'>
                      <div
                        className='sw-company rounded-5 border border-primary'
                        style={{ minHeight: '200px' }}>
                        <div className='d-flex flex-column justify-content-between h-100'>
                          <div className='sw-company-header'>
                            <div>
                              <img
                                className='logo-company p-1 bg-body  rounded-5'
                                src={
                                  company?.setting?.logo?.web_path ||
                                  toAbsoluteUrl('/media/avatars/swiver-avatar.png')
                                }
                                alt=''
                              />
                            </div>
                            <div className='header-company'>
                              <h3>{company?.name}</h3>
                              <div className='sw-company-matricule'>
                                <div className='w-20px'>
                                  {!!company?.setting?.country && (
                                    <PhoneDisplayFlag code={company?.setting?.country as any} />
                                  )}
                                </div>
                                <span className='ms-2 fs-4 fw-semibold text-white'>
                                  {company?.setting?.tax_number}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between bg-body'>
                            <div className='sw-company-footer'>
                              <h5 className='sw-info-profil'>
                                <FormattedMessage id='PROFILE.TITLE' />
                              </h5>
                              <h3 className='sw-info-role'>
                                {user?.first_name} {user?.last_name}
                              </h3>
                            </div>
                            <div className='sw-button-action'>
                              <button
                                className='btn btn-shadow btn-primary btn-submit'
                                onClick={() => changeCompany(company?.id)}>
                                <FormattedMessage id='COMMON.ACCESS' />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {addCompany && (
            <>
              <div
                className='text-end position-absolute  w-100'
                style={{ top: '20px', [direction === 'RTL' ? 'left' : 'right']: '20px' }}>
                <button
                  className='btn btn-secondary p-3 lh-1'
                  onClick={() => {
                    setAbortAddCompany(true);
                  }}>
                  <i className='fs-1 ms-1 fa-solid fa-xmark'></i>
                </button>
              </div>
              <AddCompany
                // handleChangeCompany={changeCompany}
                closeModal={handleCloseAdd}></AddCompany>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal contentClassName='rounded-5' show={abortAddCompany} centered size='lg'>
        <Modal.Body>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex flex-column h-100 justify-content-center gap-4 text-center min-h-300px'>
              <div className='py-8'>
                <img src={toAbsoluteUrl('/media/danger.png')} alt='' />
              </div>
              <h3 className='ff-poppins fw-normal fs-3'>
                <FormattedMessage id='STOP.CREATING.NEW.PROFILE' />
              </h3>
              <p className='ff-poppins fw-normal'>
                <FormattedMessage id='CONTINUE.CREATING.NEW.PROFILE' />
              </p>
            </div>
            <div className='d-flex justify-content-between'>
              <button
                className='btn btn-link text-muted'
                onClick={() => {
                  setAbortAddCompany(false);
                  setAddCompany(false);
                }}>
                <FormattedMessage id='COMMON.QUIT' />
              </button>
              <button
                className='btn btn-primary btn-shadow btn-submit'
                onClick={() => {
                  setAbortAddCompany(false);
                }}>
                <FormattedMessage id='FORM.CONTINUE' />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CompanySelection;
