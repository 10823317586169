import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ProfileExtraModule } from '../../../models/ProfileExtras';
import { getArrayFromObject } from '../../../utils/utils';

const UpgradeModalExtras = () => {
  const extras: ProfileExtraModule[] = useWatch({ name: 'plan.extras_modules', defaultValue: [] });
  const extraArray = useMemo(() => getArrayFromObject(extras), [extras]);
  return (
    <div className='d-flex gap-2 align-items-center flex-wrap'>
      <div className='upgrade-modal-extras-title'>
        <FormattedMessage id='UPGRADE_MODAL.INCLUDED_EXTRAS' />
      </div>
      {extraArray?.map((extra, index) => (
        <div className='badge badge-light-primary' key={index}>
          {extra.name}
        </div>
      ))}
    </div>
  );
};
export default UpgradeModalExtras;
