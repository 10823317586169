import { FC, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../setup/Store';
import { Permission, PermissionPlan, UserPlan } from '../../modules/auth/models/UserModel';

const isPermissionFound = (arr1: Permission[], arr2: Permission[]) =>
  arr1.some((ai) => arr2?.includes(ai));
const isPlanPermissionFound = (arr1: PermissionPlan[], arr2: PermissionPlan[]) =>
  arr1.some((ai) => arr2?.includes(ai));
const isRoleFound = (arr1: string[], arr2: string[]) => arr1?.some((ai) => arr2?.includes(ai));

const isExtraFound = (arr1: string[], arr2: string[]) => arr1?.some((ai) => arr2?.includes(ai));

export type AuthorizeInputs = {
  roles: string[];
  permissions: Permission[];
  planPermissions: PermissionPlan[];
  userRoles: string[];
  userPermissions: Permission[];
  userPlanPermissions: PermissionPlan[];
  userPlan: UserPlan;
  plans: UserPlan[];
  userExtras: string[];
  extras: string[];
};
export const useGuard = () => {
  const userPermissions = useSelector(
    (state: RootState) => state.auth.user?.current_company.permissions
  );
  const userPlanPermissions = useSelector(
    (state: RootState) => state.auth.user?.current_plan.planPermissions
  );
  const userRoles = useSelector((state: RootState) => state.auth.user?.roles);
  const userPlan = useSelector((state: RootState) => state.auth.user?.current_plan?.name);
  const moduleSubscriptions = useSelector(
    (state: RootState) => state.auth.user?.current_company?.module_subscriptions
  );
  const enableExtras = useSelector(
    (state: RootState) => state.auth.user?.current_plan?.enable_extras_module
  );
  const userExtras = useMemo(
    () =>
      !enableExtras
        ? []
        : (moduleSubscriptions || []).map((subscription) => subscription.extras_module?.reference),
    [moduleSubscriptions, enableExtras]
  );

  const doAuthorize = useMemo(
    () =>
      (
        {
          roles = [],
          permissions = [],
          planPermissions = [],
          plans = [],
          extras = [],
          onAllow = () => {},
          onDeny = () => {},
        }: {
          roles?: string[];
          permissions?: Permission[];
          planPermissions?: PermissionPlan[];
          plans?: UserPlan[];
          extras?: string[];
          onDeny?: () => void;
          onAllow?: () => void;
        },
        authorize: ((authorizeInputs: AuthorizeInputs) => boolean) | boolean = true
      ) => {
        const isAllowed =
          typeof authorize === 'boolean'
            ? authorize
            : authorize({
                roles,
                permissions,
                userRoles: userRoles || [],
                userPermissions: userPermissions || [],
                planPermissions: planPermissions || [],
                userPlanPermissions: userPlanPermissions || [],
                plans,
                userPlan: userPlan || 'SWIVER_F6',
                userExtras,
                extras,
              });
        isAllowed ? onAllow() : onDeny();
        return isAllowed;
      },
    [userRoles, userPermissions, userPlanPermissions, userExtras, userPlan]
  );

  return doAuthorize;
};

export const authorizeOnRoleOrPermissionAndPermissionPlan = (inputs: AuthorizeInputs) => {
  const { roles, permissions, userRoles, userPermissions, planPermissions, userPlanPermissions } =
    inputs;
  return (
    isPlanPermissionFound(userPlanPermissions!, planPermissions) &&
    ((!isRoleFound(userRoles || [], roles) && isPermissionFound(userPermissions!, permissions)) ||
      isRoleFound(userRoles || [], roles))
  );
};
export const authorizeOnRoleOrPermissionAndPermissionPlanOrExtra = (inputs: AuthorizeInputs) => {
  const { roles, permissions, userRoles, userPermissions, extras, userExtras } = inputs;
  return (
    (isExtraFound(userExtras || [], extras) &&
      (isRoleFound(userRoles || [], roles) || isPermissionFound(userPermissions!, permissions))) ||
    authorizeOnRoleOrPermissionAndPermissionPlan(inputs)
  );
};

export const denyOnPlan = (inputs: AuthorizeInputs) => {
  const { userPlan, plans } = inputs;
  return !plans.includes(userPlan);
};
export const authorizeOnPlan = (inputs: AuthorizeInputs) => {
  const { userPlan, plans } = inputs;
  return plans.includes(userPlan);
};

export const authorizeOnRoleOrPermission = (inputs: AuthorizeInputs) => {
  const { roles, permissions, userRoles, userPermissions } = inputs;
  return isPermissionFound(userPermissions!, permissions) || isRoleFound(userRoles!, roles);
};
export const authorizeOnRoleAndPermission = (inputs: AuthorizeInputs) => {
  const { roles, permissions, userRoles, userPermissions } = inputs;
  return isPermissionFound(userPermissions!, permissions) && isRoleFound(userRoles!, roles);
};
export const authorizeOnRoleAndPlanPermission = (inputs: AuthorizeInputs) => {
  const { roles, planPermissions, userRoles, userPlanPermissions } = inputs;
  return (
    isPlanPermissionFound(planPermissions!, userPlanPermissions) && isRoleFound(userRoles!, roles)
  );
};
export const authorizeOnRole = (inputs: AuthorizeInputs) => {
  const { roles, userRoles } = inputs;
  return isRoleFound(userRoles!, roles);
};
export const authorizeOnPermissions = (inputs: AuthorizeInputs) => {
  const { permissions, userPermissions } = inputs;
  return isPermissionFound(userPermissions!, permissions);
};
export const denyOnRole = (inputs: AuthorizeInputs) => {
  const { roles, userRoles } = inputs;
  return !isRoleFound(userRoles!, roles);
};
export const denyOnPermissions = (inputs: AuthorizeInputs) => {
  const { permissions, userPermissions } = inputs;
  return !isPermissionFound(userPermissions!, permissions);
};

const Guard: FC<{
  alt?: ReactNode;
  roles?: string[];
  permissions?: Permission[];
  authorize?: ((authorizeInputs: AuthorizeInputs) => boolean) | boolean;
  planPermissions?: PermissionPlan[];
  extras?: string[];
  onDeny?: () => void;
  onAllow?: () => void;
  plans?: UserPlan[];
}> = ({
  alt = <></>,
  roles = [],
  permissions = [],
  planPermissions = [],
  authorize = true,
  plans = [],
  extras = [],
  children,
  onAllow = () => {},
  onDeny = () => {},
}) => {
  const userPermissions =
    useSelector((state: RootState) => state.auth.user?.current_company.permissions) || [];
  const userPlanPermissions =
    useSelector((state: RootState) => state.auth.user?.current_plan.planPermissions) || [];
  const userRoles = useSelector((state: RootState) => state.auth.user?.roles) || [];
  const userPlan = useSelector((state: RootState) => state.auth.user?.current_plan.name);
  const enableExtras = useSelector(
    (state: RootState) => state.auth.user?.current_plan?.enable_extras_module
  );
  const moduleSubscriptions = useSelector(
    (state: RootState) => state.auth.user?.current_company?.module_subscriptions
  );

  const userExtras = useMemo(
    () =>
      !enableExtras
        ? []
        : (moduleSubscriptions || []).map((subscription) => subscription.extras_module?.reference),
    [moduleSubscriptions, enableExtras]
  );
  const isAuthorized = useMemo(() => {
    const isAllowed =
      typeof authorize === 'boolean'
        ? authorize
        : authorize({
            roles,
            permissions,
            userRoles,
            userPermissions,
            planPermissions,
            userPlanPermissions,
            userPlan: userPlan || 'SWIVER_F6',
            plans,
            extras,
            userExtras,
          });
    isAllowed ? onAllow() : onDeny();
    return isAllowed;
  }, [roles, userRoles, permissions, userPermissions, planPermissions, userPlanPermissions]);

  return useMemo(
    () => <>{isAuthorized ? children : isAuthorized === false ? alt : <></>}</>,
    [isAuthorized, children, alt]
  );
};
export default Guard;
