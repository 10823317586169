import { Navigate, Route, Routes } from 'react-router-dom';
import Guard, {
  authorizeOnRole,
  authorizeOnRoleOrPermissionAndPermissionPlan,
  authorizeOnRoleOrPermissionAndPermissionPlanOrExtra,
} from '../components/Security/Guard';
import { SuspensedView } from '../components/SuspendedView';
import { MasterLayout } from '../layout/MasterLayout';
import AcountingRoutes from '../pages/Accounting/AcountingRoutes';
import DocumentRoutes from '../pages/Documents/DocumentRoutes';
import ListDocumentRoutes from '../pages/Documents/ListDocuments/ListDocumentsRoutes';
import ProfileRoutes from '../pages/Profile/ProfileRoutes';
import SettingsRoutes from '../pages/Settings/SettingsRoutes';
import StockRoutes from '../pages/Stock/StockRoutes';
import { lazyWithRetry } from '../utils/LazyWithRetry';
import ReportsRoutes from './ReportsRoutes';
import TicketsRoutes from '../pages/Tickets/TicketsRoutes';
const PointOfSale = lazyWithRetry(() => import('../pages/PointOfSale'));

// Lazy load modules
const CustomersTable = lazyWithRetry(() => import('../pages/Customers/CustomerList'));
const AddCustomerOrSupplier = lazyWithRetry(
  () => import('../pages/CustomerAndSupplier/AddCustomerOrSupplier/AddCustomerOrSupplier')
);
const ProductList = lazyWithRetry(() => import('../pages/Products/ProductList'));
const AddProduct = lazyWithRetry(() => import('../pages/Products/AddProduct/AddProduct'));
const SupplierList = lazyWithRetry(() => import('../pages/Suppliers/SupplierList'));
const ContactDetails = lazyWithRetry(
  () => import('../pages/Customers/ContactDetails/ContactDetails')
);
const ProductDetails = lazyWithRetry(
  () => import('../pages/Products/ProductDetails/ProductDetails')
);
const Dashboard = lazyWithRetry(() => import('../pages/Dashboard/Dashboard'));
const ChecksAndDraftsList = lazyWithRetry(
  () => import('../pages/ChecksAndDrafts/ChecksAndDraftsList')
);
const AddOrEditCheckOrDraft = lazyWithRetry(
  () => import('../pages/ChecksAndDrafts/AddOrEditCheckOrDraft')
);
const Unauthorized = lazyWithRetry(() => import('../pages/Unauthorized'));
const Home = lazyWithRetry(() => import('../pages/Home'));
function PrivateRoutes() {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='/dashboard'
          element={
            <Guard roles={['ROLE_ADMIN']} authorize={authorizeOnRole}>
              <SuspensedView>
                <Dashboard />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/pos/:id'
          element={
            <Guard
              planPermissions={['invoiceManagement']}
              permissions={['Invoice.read', 'Invoice.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}>
              <SuspensedView>
                <PointOfSale />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/customers'
          element={
            <Guard
              planPermissions={['customerManagement']}
              permissions={['Customer']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <CustomersTable />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/customers/add'
          element={
            <Guard
              planPermissions={['customerManagement']}
              permissions={['Customer.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddCustomerOrSupplier type='customer' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/customers/edit/:id'
          element={
            <Guard
              planPermissions={['customerManagement']}
              permissions={['Customer.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddCustomerOrSupplier type='customer' mode='edit' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/customers/details/:id'
          element={
            <Guard
              planPermissions={['customerManagement']}
              permissions={['Customer.read']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ContactDetails type='customer' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/suppliers/details/:id'
          element={
            <Guard
              planPermissions={['supplierManagement']}
              permissions={['Supplier.read']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ContactDetails type='supplier' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/products'
          element={
            <Guard
              planPermissions={['productsManagement']}
              permissions={['Catalog']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ProductList />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/checks/add'
          element={
            <Guard
              planPermissions={['checkAndDraft']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddOrEditCheckOrDraft />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/checks/edit/:id'
          element={
            <Guard
              planPermissions={['checkAndDraft']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddOrEditCheckOrDraft mode='edit' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/unauthorized'
          element={
            <SuspensedView>
              <Unauthorized />
            </SuspensedView>
          }
        />
        <Route
          path='/settings/*'
          element={
            <Guard
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRole}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SettingsRoutes />
            </Guard>
          }
        />
        <Route
          path='/tickets/*'
          element={
            <SuspensedView>
              <TicketsRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='/accounting/*'
          element={
            <Guard
              planPermissions={['accountingManagement']}
              permissions={['Accounting']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <AcountingRoutes />
            </Guard>
          }
        />
        <Route
          path='/stock/*'
          element={
            <Guard
              planPermissions={['stockManagement']}
              permissions={['Inventory']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <StockRoutes />
            </Guard>
          }
        />
        <Route
          path='/reports/*'
          element={
            <Guard
              roles={['ROLE_ADMIN']}
              planPermissions={['reports']}
              permissions={['Reports']}
              extras={['REPORTS']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlanOrExtra}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <ReportsRoutes />
            </Guard>
          }
        />
        <Route path='/document/*' element={<DocumentRoutes />} />
        <Route path='/profile/*' element={<ProfileRoutes />} />

        <Route
          path='/products/add'
          element={
            <Guard
              planPermissions={['productsManagement']}
              permissions={['Catalog.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddProduct />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/products/edit/:id'
          element={
            <Guard
              planPermissions={['productsManagement']}
              permissions={['Catalog.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddProduct mode='edit' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/products/details/:id'
          element={
            <Guard
              planPermissions={['productsManagement']}
              permissions={['Catalog.read']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ProductDetails />
              </SuspensedView>
            </Guard>
          }
        />

        <Route
          path='/suppliers'
          element={
            <Guard
              planPermissions={['supplierManagement']}
              permissions={['Supplier']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <SupplierList />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/suppliers/add'
          element={
            <Guard
              planPermissions={['supplierManagement']}
              permissions={['Supplier.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddCustomerOrSupplier type='supplier' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route
          path='/suppliers/edit/:id'
          element={
            <Guard
              planPermissions={['supplierManagement']}
              permissions={['Supplier.read.write']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <AddCustomerOrSupplier type='supplier' mode='edit' />
              </SuspensedView>
            </Guard>
          }
        />
        <Route path='/documents/*' element={<ListDocumentRoutes />} />
        <Route
          path='/checks'
          element={
            <Guard
              planPermissions={['checkAndDraft']}
              permissions={['Accounting.manage.checks_draft']}
              roles={['ROLE_ADMIN']}
              authorize={authorizeOnRoleOrPermissionAndPermissionPlan}
              alt={<Navigate to='/unauthorized' replace={true} />}>
              <SuspensedView>
                <ChecksAndDraftsList />
              </SuspensedView>
            </Guard>
          }
        />
        <Route path='/sign-in' element={<Navigate to='/' />} />
        <Route path='/welcome/*' element={<Navigate to='/' />} />
        <Route path='/sign-up' element={<Navigate to='/' />} />
        <Route path='/forgot-password' element={<Navigate to='/' />} />
        <Route
          path='/home'
          element={
            <SuspensedView>
              <Home />
            </SuspensedView>
          }
        />
        <Route path='/' element={<Navigate to='/home' />} />
        <Route path='/expired' element={<Navigate to='/home' />} />
        <Route path='*' element={<Navigate to='error/404' />} />
      </Route>
    </Routes>
  );
}

export default PrivateRoutes;
export { SuspensedView };
