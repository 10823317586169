import React, { useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { toggleFullScreen } from '../../setup/redux/reducers/UtilsReducers';
import { RootState } from '../../setup/Store';
import SWTopNav from '../../stories/SwTopNav/SwTopNav';
import Toolbar from '../components/Toolbar/Toolbar';
import { MenuComponent } from '../utils/ts/components';
import { Content } from './components/Content';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { ScrollTop } from './components/ScrollTop';
import { Sidebar } from './components/Sidebar';

const MasterLayout: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const topNavType = useSelector((state: RootState) => state.page.topNavType);
  const navTabs = useSelector((state: RootState) => state.page.topNavTabs);
  const fullscreen = useSelector((state: RootState) => state.utils.FullScreen);
  const handle = useFullScreenHandle();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, []);

  useEffect(() => {
    if (fullscreen && handle) {
      handle?.enter();
    } else {
      handle?.exit();
    }
  }, [fullscreen]);
  useEffect(() => {
    if (!user) return;
  }, [user]);
  const dispatch = useDispatch();
  return (
    <>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
                <Toolbar />
                {/* {daysDifference <= 14 && <Banner daysDifference={daysDifference} />} */}
                {!!topNavType && <SWTopNav navTabs={navTabs} />}
                <FullScreen
                  handle={handle}
                  className='min-w-100 min-h-100'
                  onChange={(state) => {
                    if (!state && fullscreen) {
                      dispatch(toggleFullScreen());
                    }
                  }}>
                  <Content>
                    <Outlet />
                  </Content>
                </FullScreen>
              </div>
              {/* <FooterWrapper /> */}
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </>
  );
};

export { MasterLayout };
